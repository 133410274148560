/* *** Fevre River Database *** */
/* (C) 2022 - Joti Software, LLC */

// DataTables Component //

// TODO: abstract, and publish as npm package
// 	- require user to import styling package separately
// 	- take passed in options object, OR columns and data props (w/ optional dom prop)
// 	- change to vanilla <table />, with 'className' prop for BS/styling classes

import React, { Component } from 'react';
import DataTable from 'datatables.net';
import { Table } from 'react-bootstrap';
// import 'datatables.net-bs5';		// TODO: figure out why not working correctly, enable, and remove regular CDN link from index.html

class DataTableComponent extends Component {
	constructor(props) {
		super(props);

		this.tableHeight = this.tableHeight.bind(this);
		this.reloadTableData = this.reloadTableData.bind(this);

		this.state = {
			table: null
		};
	}

	componentDidMount() {
		const options = {
				dom: '<"DataTable__title float-start"><"float-end"<"d-inline-block"f>>ti',
				data: this.props.data,
				columns: this.props.columns,
				order: [],
				scrollY: this.tableHeight(),
				scrollCollapse: true,
				paging: false,
				language: {
					emptyTable: 'Loading...',
					search: '',
					searchPlaceholder: 'Search'
				},
				createdRow: ((row, data) => { row.onclick = e => this.props.onRowClick(e, data.DT_RowId) }),	// attach onclick handler to rows, and call parent handler via props
		};
		const table = new DataTable(this.el, options);
		this.setState({ table: table });
	}

	componentWillUnmount() {
		this.state.table.destroy(true);
	}

	shouldComponentUpdate(nextProps) {
		if (nextProps.data.length !== this.props.data.length) {
			this.reloadTableData(nextProps.data);
		}
		return false;
	}

	tableHeight() {
		const offset = this.el.offsetTop;
		const viewportHeight = window.innerHeight;
		return viewportHeight - offset - 135;
	}

	reloadTableData(data) {
		const table = this.state.table;
		table.clear();
		table.rows.add(data);
		table.columns.adjust();
		table.draw();
	}

	render() {
		return (
			<div>
				<Table striped hover className="align-middle" ref={el => this.el = el} />
            </div>);
    }
}

export default DataTableComponent;
