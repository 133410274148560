/* *** Fevre River Database *** */
/* (C) 2022 - Joti Software, LLC */

// Login Button Component //

import React from 'react';
import { Button } from 'react-bootstrap';
import { useAuth0 }  from '@auth0/auth0-react';

const LoginButton = (props) => {
	const { loginWithRedirect } = useAuth0();
    return (
        <Button {...props} onClick={() => loginWithRedirect()}>
        	<span className="mx-5">Login</span>
        </Button>
    );
};

export default LoginButton;
