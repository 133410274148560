/* *** Fevre River Database *** */
/* (C) 2021 - Joti Software, LLC */

// Location Card Components //
/*
	<LocationCard />
	<LocationPopover />
	<LocationInputCard />
*/

import React, { Component } from 'react';
import { Card, Col, Collapse, Form, ListGroup, Row } from 'react-bootstrap';
import TextareaAutosize from 'react-textarea-autosize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons'
import produce from 'immer';
import axios from 'axios';

import ReactSelect from './ReactSelect';
import Popover from './Popover';
import Tooltip from './Tooltip';

const locationOptionsURI = process.env.REACT_APP_API_URI + '/location-options/';
const addOptionURI = locationOptionsURI + 'add/';

const baseLockedLocationKey = 'fevreriverdb.lockedLocation';

const locationDescriptions = {
	property: 'Property specifies the specific Property where the item is located.',
	building: 'Building specifies the building on the Property, where the item is located.',
	room: 'Room specifies the Room within the Building, where the item is located.',
	wall: 'Wall specifies the Wall (East, Northeast, North, Northwest, West, Southwest, South, or Southeast) within the Room, where the item is located.',
	case: 'Cabinet specifies the Cabinet within the Room where the item is located. Cabinets are numbered clockwise, starting from the Room\'s main entryway.',
	shelf: 'Shelf specifies the Shelf within the Cabinet, where the item is located. Shelves are lettered from the top down.',
	position: 'Position specifies the place number within the Shelf (from left to right, or clockwise from top) where the item is located.'
};

const capitalizeFirst = string => string[0].toUpperCase() + string.substring(1);	// Capitalize first letter of string

// Location Card
/*
	collapsability is enabled when props.handleCollapseToggle is passed in
	requires:
		- props.handleCollapseToggle: handler function for when collapse is toggled (controlled by parent)
		- props.collapseIn: current collapse state (passed in by controlling parent)
*/
function LocationCardRow(props) {
	const sizeClass = (props.size === 'xs') ? 'popover-sm' : '';
	const positionValue = (location) => (location.positionX && location.positionY) ? (location.positionX + ' of ' + location.positionY) : '';
	const value = (props.keyName === 'position') ? positionValue(props.location) : props.location[props.keyName];
	const cardRow = (
		<Tooltip placement="top" wrapperDisplay="block" title={value}>
			<ListGroup.Item className="text-light">
				<Row>
					<Col md="auto">
						<em className="text-muted">{props.label}</em>
					</Col>
					<Col className="text-truncate text-end">
						<span>{(value) ? value : <em className="text-muted">No {props.label}</em>}</span>
					</Col>
				</Row>
			</ListGroup.Item>
		</Tooltip>
	);
	return (
		(props.descriptionPopover)
		?	(
				<Popover placement="left" className={sizeClass} title={props.label} content={locationDescriptions[props.keyName]}>
					{cardRow}
				</Popover>
			)
		:	cardRow
	);
}
function LocationCard(props) {
	const size = props.size || 'sm';
	const descriptionPopover = (props.descriptionPopover !== undefined) ? props.descriptionPopover : true;
	const title = props.title || 'Location';
	const collapseIn = (props.handleCollapseToggle) ? props.collapseIn : true;
	const accordionClass =
		(props.handleCollapseToggle)
		? 'accordion-card' + ((!collapseIn) ? ' collapsed' : '')
		: ''
	return (
		(props.location.property)
		?	(
				<Card className={`card-${size} card-list-hover ${props.className} ${accordionClass}`}>
					<Card.Header onClick={() => ((props.handleCollapseToggle) ? props.handleCollapseToggle(title) : '')}><h6 className="mb-0"><strong>{title}:</strong></h6></Card.Header>
					<Collapse in={collapseIn}>
						<div>
							<ListGroup variant="flush">
								<LocationCardRow keyName="property" label="Property" size={size} location={props.location} descriptionPopover={descriptionPopover} />
								<LocationCardRow keyName="building" label="Building" size={size} location={props.location} descriptionPopover={descriptionPopover} />
								<LocationCardRow keyName="room" label="Room" size={size} location={props.location} descriptionPopover={descriptionPopover} />
								<LocationCardRow keyName="wall" label="Wall" size={size} location={props.location} descriptionPopover={descriptionPopover} />
								<LocationCardRow keyName="case" label="Case" size={size} location={props.location} descriptionPopover={descriptionPopover} />
								<LocationCardRow keyName="shelf" label="Shelf" size={size} location={props.location} descriptionPopover={descriptionPopover} />
								<LocationCardRow keyName="position" label="Position" size={size} location={props.location} descriptionPopover={descriptionPopover} />
							</ListGroup>
							<Card.Body>
								<Card.Text><em className="text-muted">Location Notes: </em><span className="float-end text-light">{(props.location.notes) ? props.location.notes : <em className="text-muted">No Notes</em>}</span></Card.Text>
							</Card.Body>
						</div>
					</Collapse>
				</Card>
			)
		: ''
	);
}

// Location Popover
/*
	Example:

	<LocationPopover trigger="hover|click|focus" placement="top|right|bottom|left" location={locationObject}>
		<Button>Example LocationPopover Target</Button>
	</LocationPopover>
*/
function LocationPopover(props) {
	return (
		<Popover
			trigger={props.trigger}
			placement={props.placement}
			className="location-card-popover"
			content={
				<LocationCard size="xs" descriptionPopover={false} location={props.location} />
			}
		>
			{props.children}
		</Popover>
	);
}

// Location Input Card
function LocationLockButton(props) {
	const icon = (props.locked) ? faLock : faLockOpen;
	const baseClass = 'LocationLockButton';
	const lockButtonClass = (props.locked) ? baseClass + '--locked' : '';
	const tooltipText = (props.locked) ? 'Click to unlock location settings' : 'Click to lock location settings between item additions';
	return (
		(!props.disabled)
		?	<Tooltip className={props.className} placement="top" title={tooltipText}>
				<FontAwesomeIcon className={`${baseClass} ${lockButtonClass} ${props.className}`} icon={icon} onClick={props.onClick} />
			</Tooltip>
		:	''
	);
}
function LocationInputCardRow(props) {
	const name = [props.name, props.keyName, props.parentName, props.optionId].join('.');
	return (
			<ListGroup.Item className="text-light pe-2">
				<Row>
					<Col>
						<Popover placement="left" className="popover-sm" title={props.label} content={locationDescriptions[props.keyName]}>
							<em className="text-muted">{props.label}</em>
						</Popover>
					</Col>
					<Col md="8" className="text-end">
						<ReactSelect size="sm" variant="dark" name={name} placeholder={props.label} options={props.options} value={props.value} onChange={props.onChange} isCreatable={props.editable} isLoading={props.loading} isDisabled={props.disabled} required={props.required} tabIndex={props.tabIndex} />
						{(props.required) ? (<Form.Control.Feedback type="invalid">Please select a {props.label}.</Form.Control.Feedback>) : ''}
					</Col>
				</Row>
			</ListGroup.Item>
	);
}
function LocationInputCardPositionRow(props) {
	const nameX = [props.name, props.keyName + 'X', props.parentName, props.optionId].join('.');
	const nameY = [props.name, props.keyName + 'Y', props.parentName, props.optionId].join('.');
	const invalidMissingX = (props.valueY && !props.valueX);
	const invalidMissingY = (props.valueX && !props.valueY);
	const invalidXGreaterThanY = (Number(props.valueX) > Number(props.valueY));
	const invalid = (invalidMissingX || invalidMissingY || invalidXGreaterThanY);
	const invalidFeedback = (invalidMissingX) ? 'Please select a first value.' : (invalidMissingY) ? 'Please select a second value.' : (invalidXGreaterThanY) ? 'Please select a second value higher than the first.' : '';
	return (
			<ListGroup.Item className="text-light pe-2">
				<Row>
					<Col>
						<Popover className="popover-sm" placement="left" title={props.label} content={locationDescriptions[props.keyName]}>
							<em className="text-muted">{props.label}</em>
						</Popover>
					</Col>
					<Col md="8" className="text-end">
						<Row>
							<Col>
								<ReactSelect size="sm" variant="dark" name={nameX} placeholder="#" options={props.options} value={props.valueX} onChange={props.onChange} isCreatable={props.editable} isLoading={props.loading} isDisabled={props.disabled} required={props.required} isInvalid={invalid} tabIndex={props.tabIndexX} />
							</Col>
							<Col md="auto" className="px-1">of</Col>
							<Col>
								<ReactSelect size="sm" variant="dark" name={nameY} placeholder="#" options={props.options} value={props.valueY} onChange={props.onChange} isCreatable={props.editable} isLoading={props.loading} isDisabled={props.disabled} required={props.required} isInvalid={invalid} tabIndex={props.tabIndexY} />
							</Col>
						</Row>
					</Col>
				</Row>
				<Row className="text-end">
					<Col className={(invalid) ? 'is-invalid' : ''}>
						{(props.required) ? (<Form.Control.Feedback type="invalid">Please select a {props.label}.</Form.Control.Feedback>) : ''}
						{(invalid) ? (<Form.Control.Feedback type="invalid">{invalidFeedback}</Form.Control.Feedback>) : ''}
					</Col>
				</Row>
			</ListGroup.Item>
	);
}
class LocationInputCard extends Component {
	constructor(props) {
		super(props);

		this.handleSelectChange = this.handleSelectChange.bind(this);
		this.handleTextareaChange = this.handleTextareaChange.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleOptionAdded = this.handleOptionAdded.bind(this);
		this.handleLockClick = this.handleLockClick.bind(this);
		this.inputIsDisabled = this.inputIsDisabled.bind(this);

		this.state = {
			locationKeys: [
				"property",
				"building",
				"room",
				"wall",
				"case",
				"shelf",
				"position"
			],

			loadingInputs: {
				property: false,
				building: false,
				room: false,
				wall: false,
				case: false,
				shelf: false,
				position: false
			},

			locationLocked: false,
			lockedInputs: {},
			lockedLocationKey: '',

			options: {},
			propertyOptions: null,
			buildingOptions: null,
			roomOptions: null,
			wallOptions: null,
			caseOptions: null,
			shelfOptions: null,
			positionOptions: null
		};
	}

	componentDidMount() {
		this.setState({ lockedLocationKey: [baseLockedLocationKey, this.props.name].join('.') }, () => {
			if (!this.props.disableLocking) {
				// Check for locked location, & set state
				const lockedLocation = JSON.parse(localStorage.getItem(this.state.lockedLocationKey));
				const locked = (lockedLocation !== null);
				this.setState({ locationLocked: locked });
				if (locked) {
					this.props.onChange(lockedLocation, this.props.name);
				}
				this.setLockedInputs(lockedLocation, locked);
			}
		});

		// Load location options
		axios.get(locationOptionsURI)
			.then(response => {
				const locationOptions = response.data[0];
				this.setState({ options: locationOptions }, () => {
					this.setOptions(this.props.location);
				});
			})
			.catch(err => console.log(err));
	}

	handleSelectChange(selectedOption, action) {
		if (action.action === 'create-option') {
			const name = action.name.split('.')[1];
			const parentName = action.name.split('.')[2];
			const parentOptionId = action.name.split('.')[3];
			const value = selectedOption.value;
			this.handleOptionAdded(name, parentName, parentOptionId, value);
		}
		else {
			const name = action.name.split('.')[1];
			const value = selectedOption.value;
			this.handleChange(name, value);
		}
	}

	handleTextareaChange(e) {
		const name = e.target.name.split('.')[1];
		const value = e.target.value;
		this.handleChange(name, value);
	}

	handleOptionAdded(name, parentName, parentOptionId, value) {
		// add new location option to db
		this.setState(produce(draftState => { draftState.loadingInputs[name] = true }));
		const keyName = (name === 'positionX' || name === 'positionY') ? 'position' : name;
		const parentType = capitalizeFirst(parentName) + 'Option' + ((parentName === 'Location') ? 's' : '');
		const type = capitalizeFirst(keyName) + 'Option';
		const body = { parentType, type, value };
		axios.post(addOptionURI+parentOptionId, body)
			.then(response => {
				// replace options with new updated options
				this.setState(produce(draftState => {
					draftState.options = response.data[0];
					draftState.loadingInputs[keyName] = false;
				}), () => {
					this.handleChange(name, value);
				});
			})
			.catch(err => console.log(err));
	}

	handleChange(name, value) {
		if (value !== this.props.location[name]) {	// don't make changes if value is the same
			// create updated location object
			let location = { ...this.props.location };
			location[name] = value;

			// reset all location values and options sets after current input
			const keyIndex = this.state.locationKeys.indexOf(name);
			if (keyIndex > -1) {	// don't do anything for location.notes
				this.state.locationKeys.slice(keyIndex+1).forEach((currentKey, i) => {
					if (currentKey === 'position') {
						location.positionX = '';
						location.positionY = '';
					}
					else {
						location[currentKey] = '';
					}
					const currentOptionsName = currentKey + 'Options';
					this.setState(produce(draftState => { draftState[currentOptionsName] = null }));
				});
			}

			// set options based on new location object, and call the parent's change handler
			this.setOptions(location);
			this.props.onChange(location, this.props.name);
		}
	}

	handleLockClick() {
		const locked = !this.state.locationLocked;
		this.setState({ locationLocked: locked }, () => {
			(locked) ? localStorage.setItem(this.state.lockedLocationKey, JSON.stringify(this.props.location)) : localStorage.removeItem(this.state.lockedLocationKey);
			this.setLockedInputs(this.props.location, locked);
		});
	}

	setLockedInputs(lockedLocation, locked) {
		let lockedInputs = {};
		this.state.locationKeys.forEach((currentKey, i) => {
			const locationValueExists = (currentKey === 'position') ? (lockedLocation && lockedLocation.positionX && lockedLocation.positionY) : (lockedLocation && lockedLocation[currentKey].length > 0);
			lockedInputs[currentKey] = (locationValueExists && locked);
		});
		this.setState({ lockedInputs: lockedInputs });
	}

	// Traverse down through options document as far as set location values exist, setting options in state
	setOptions(location) {
		// setup starting options
		let currentOptions = this.state.options;
		// for every location type in locationKeys
		for (let [i, currentKey] of this.state.locationKeys.entries()) {
			// if first location type, skip straight to assigning options
			if (i !== 0) {
				// get selected value for previous location type, and if value is selected, get options for value
				const previousKey = this.state.locationKeys[i - 1];
				const previousValue = location[previousKey];
				if (previousValue) {
					currentOptions = currentOptions.options.find(option => option.name === previousValue);
				}
				else {
					break;
				}
			}
			// set options in state, and continue to checking next location type
			const currentOptionsName = currentKey + 'Options';
			const options = currentOptions;
			this.setState(produce(draftState => { draftState[currentOptionsName] = options }));
		}
	}

	inputIsDisabled(index, prevKey, options, loading, locked) {
		// setup
		const prevOptionsName = prevKey + 'Options';
		const prevOptions = (this.state[prevOptionsName]) ? this.state[prevOptionsName].options.map(currentOption => currentOption.name) : '';
		const prevValue = this.props.location[prevKey];
		// input is disabled if: input is locked OR input is loading OR (input has no options AND is not first empty input) (to allow adding new options)
		const optionsAreEmpty = (options == null || options.length < 1);
		const firstEmptyInput = (index === 0 || (prevOptions.length > 0 && prevValue))
		const emptyAndNotFirst = (optionsAreEmpty && !firstEmptyInput);
		return (locked || loading || emptyAndNotFirst);
	}

	locationRows() {
		return this.state.locationKeys.map((currentKey, i) => {
			// setup name/label
			const prevKey = (i > 0) ? this.state.locationKeys[i - 1] : '';
			const label = capitalizeFirst(currentKey);
			const parentName = (i === 0) ? 'Location' : prevKey;
			// setup data
			const optionsName = currentKey + 'Options';
			const options = (this.state[optionsName]) ? this.state[optionsName].options.map(currentOption => currentOption.name) : '';			
			const optionId = (this.state[optionsName]) ? this.state[optionsName]._id : '';
			const value = this.props.location[currentKey];
			// setup input state
			const editable = (this.props.editable && currentKey !== 'wall');
			const loading = this.state.loadingInputs[currentKey];
			const locked = this.state.lockedInputs[currentKey];
			const disabled = this.inputIsDisabled(i, prevKey, options, loading, locked);
			const required = this.props.requiredProperties.includes(currentKey);
			const tabIndex = (this.props.tabIndexStart !== undefined) ? (Number(this.props.tabIndexStart) + i) : null;
			// return rows
			if (currentKey === 'position') {
				const valueX = this.props.location.positionX;
				const valueY = this.props.location.positionY;
				return <LocationInputCardPositionRow name={this.props.name} key={currentKey} keyName={currentKey} label={label} options={options} valueX={valueX} valueY={valueY} onChange={this.handleSelectChange} editable={editable} parentName={parentName} optionId={optionId} loading={loading} locked={locked} disabled={disabled} required={required} tabIndexX={tabIndex} tabIndexY={tabIndex + 1} />
			}
			else {
				return <LocationInputCardRow name={this.props.name} key={currentKey} keyName={currentKey} label={label} options={options} value={value} onChange={this.handleSelectChange} editable={editable} parentName={parentName} optionId={optionId} loading={loading} locked={locked} disabled={disabled} required={required} tabIndex={tabIndex} />
			}
		});
	}

	render() {
		const title = this.props.title || "Location";
		return (
			<Card className={`card-sm card-list-hover ${this.props.className}`}>
				<Card.Header>
					<h6 className="float-start mb-0"><strong>{title}:</strong></h6>
					<LocationLockButton className="float-end" locked={this.state.locationLocked} onClick={this.handleLockClick} disabled={this.props.disableLocking} />
				</Card.Header>
				<ListGroup variant="flush">
					{this.locationRows()}
				</ListGroup>
				<Card.Body className="pe-3">
					<Card.Text>
						<em className="d-inline-block text-muted mb-2">Location Notes:</em>
						<TextareaAutosize name="location.notes" className="form-control textarea-sm variant--dark" placeholder="Location notes (optional)..." minRows="2" value={this.props.location.notes} onChange={this.handleTextareaChange} tabIndex={Number(this.props.tabIndexStart) + this.state.locationKeys.length + 1} />
					</Card.Text>
				</Card.Body>
			</Card>
		);
	}
}

export { LocationCard, LocationPopover };
export default LocationInputCard;