/* *** Fevre River Database *** */
/* (C) 2022 - Joti Software, LLC */

// Main App JS //

import React, { Component, useState, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useAuth0 }  from '@auth0/auth0-react';
import { Container, Spinner } from 'react-bootstrap';
import axios from 'axios';

import LoginButton from './components/LoginButton';
import LogoutButton from './components/LogoutButton';

import Header from './components/Header';
import { BasicHeader } from './components/Header';
import DatabaseView from './components/DatabaseView';
import IncomingBooksView from './components/IncomingBooksView';
import DetailView from './components/DetailView';
import PageNotFoundView from './components/PageNotFoundView';
import ItemNotFoundView from './components/ItemNotFoundView';

import './App.css';

const usersURI = process.env.REACT_APP_API_URI + '/users/';
const usersByEmailURI = usersURI + 'email/';

function LoadingOverlay(props) {
	const opaqueClass = (props.opaque) ? 'LoadingOverlay--opaque' : '';
	return (
		(props.show)
		?	(
				<div className={`LoadingOverlay ${opaqueClass}`}>
					<div className="d-flex align-items-center">
						<Spinner as="span" animation="border" role="status" aria-hidden="true" />
						<span className="ms-3">Loading...</span>
					</div>
				</div>
			)
		: ''
	);
}

function LoginView(props) {
	// fade in login card on page load
	const [loaded, setLoaded] = useState(false);
	useEffect(() => { setLoaded(true) }, []);
	return (
		<div className="App">
			<LoadingOverlay show={props.isLoading} />
			<BasicHeader />
			<div className="d-flex justify-content-center vh-100">
				<div id="LoginCard" className={`align-self-center text-center fade-in-on-load ${(loaded && !props.isLoading) ? 'fade-in' : ''}`}>
					<h1 className="fw-light">Welcome!</h1>
					<p className="text-muted mt-2">Click 'Login' below to begin.</p>
					<LoginButton variant="surprise" size="sm" className="mt-3" />
				</div>
			</div>
		</div>
	);
}

// temporary - TODO: remove when full user management UI (/admin) is created
function NoUserRecordView(props) {
	return (
		<div className="App">
			<BasicHeader />
			<div className="d-flex justify-content-center vh-100">
				<div className="align-self-center text-center">
					<h2 className="fw-light">No Matching User Record Found</h2>
					<p className="w-50 d-inline-block text-muted mt-3">This application has not yet been configured to allow adding of multiple users. If you need to add another user record, please contact <a href="mailto:fskhalsa@gmail.com" rel="noreferrer" target="_blank">Joti Software</a> for assistance.</p>
					<div><LogoutButton variant="dark" className="mt-3" /></div>
				</div>
			</div>
		</div>
	);
}

function App() {
	const { user, isLoading, isAuthenticated } = useAuth0();
	return (
		(isAuthenticated)
		?	<Application authUser={user} />
		:	<LoginView isLoading={isLoading} />
	);
}

class Application extends Component {
	
	constructor(props) {
		super(props);

		this.handleNavClick = this.handleNavClick.bind(this);

		this.state = {
			authUser: props.authUser,
			currentUser: {},
			noUserRecordFound: false,
			isLoading: true
		};
	}

	componentDidMount() {
		this.setState({ isLoading: true });
		axios.get(usersByEmailURI+this.state.authUser.email)
			.then(response => {
				console.log(response.data);
				if (response.data === null) {
					this.setState({ noUserRecordFound: true, isLoading: false });
				}
				else {
					this.setState({ currentUser: response.data, isLoading: false });
				}
			})
			.catch(err => console.log(err));
	}

	handleNavClick(e) {
		if (!e.ctrlKey && !e.metaKey) { // don't trigger overlay if ctrl/command key is pressed (for click to open in new tab)
			this.setState({ isLoading: true })
		}
	}

	render() {
		return (
			<div className="App">
				<LoadingOverlay show={this.state.isLoading} />
				{
					(this.state.isLoading)
					?	<BasicHeader />
					:	
						(this.state.noUserRecordFound)
						?	<NoUserRecordView />
						:
							<>
								<Header currentUser={this.state.currentUser} handleNavClick={this.handleNavClick} />
								<Container fluid>
									<Switch>
										<Route exact path="/">
											<DatabaseView currentUser={this.state.currentUser} handleNavClick={this.handleNavClick} />
										</Route>
										<Route exact path="/incoming-books">
											<IncomingBooksView currentUser={this.state.currentUser} />
										</Route>
										<Route exact path="/detail/:id">
											<DetailView currentUser={this.state.currentUser} />
										</Route>
										<Route exact path="/item-not-found">
											<ItemNotFoundView />
										</Route>
										<Route>
											<PageNotFoundView />
										</Route>
									</Switch>
								</Container>
							</>
				}
			</div>
		);
	}
}

export default App;
