/* *** Fevre River Database *** */
/* (C) 2022 - Joti Software, LLC */

// Main Database View //

import React, { Component } from 'react';
import ReactDOMServer from 'react-dom/server';
import { withRouter } from 'react-router-dom';
import produce from 'immer';
import axios from 'axios';

import AddEditItemModal from './AddEditItemModal';
import DataTable from './DataTable';

const baseURI = process.env.REACT_APP_API_URI + '/';
const itemsURI = baseURI + 'items/';

// Data Formatting
function Image(props) {
	return <div className="text-center"><img className="table-thumbnail" src={baseURI+props.item.imageRef} alt="Item" /></div>
}

function Type(props) {
	return <em>{props.item.type}<span className="text-muted"> - {props.item.medium}</span></em>;
}

/* Database View */
class DatabaseView extends Component {

	constructor(props) {
		super(props);

		this.handleItemAdded = this.handleItemAdded.bind(this);
		this.handleItemRowClick = this.handleItemRowClick.bind(this);

		this.state = {
			items: []
		};
	}

	componentDidMount() {
		axios.get(itemsURI)
			.then(response => {
				this.setState({ items: response.data });
			})
			.catch(err => console.log(err));
	}

	handleItemAdded(item) {
		this.setState(produce(draftState => { draftState.items.push(item) }));
	}

	handleItemRowClick(e, itemId) {
		if (e.ctrlKey || e.metaKey) {
			// open detail page in new tab
			window.open('/detail/'+itemId, '_blank')
		}
		else {
			// open detail page in current tab
			// this.props.handleNavClick(e);				// TODO: disabled until resolve how to close overlay on detail page load
			this.props.history.push('/detail/'+itemId);		// TODO: investigate ways to pass entire item to detail page, to avoid re-loading from server (without breaking static item links)
		}
	}

	tableColumns() {
		return [
			{ title: 'RAW', data: 'raw', searchable: true, visible: false },	// for searching (TODO: implement full server-side search capability)
			{ title: '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;', data: 'image', orderable: false },
			{ title: 'Type', data: 'type' },
			{ title: 'Title', data: 'title' },
			{ title: 'Creator', data: 'creator' },
			{ title: 'Country of Origin', data: 'country' },
			{ title: 'Language', data: 'language' },
			{ title: 'Style', data: 'style' }
		];
	}

	tableData() {
		const cellData = (cellText, emptyString) => {
			return  ReactDOMServer.renderToStaticMarkup((
				(cellText)
				?	cellText
				:	<em className="text-muted">{emptyString}</em>
			));
		};
		return this.state.items.map((currentItem, i) => {
			return {
				raw: JSON.stringify(currentItem),
				image: ReactDOMServer.renderToStaticMarkup(<Image item={currentItem} />),
				type: ReactDOMServer.renderToStaticMarkup(<Type item={currentItem} />),
				title: cellData(currentItem.title, 'No Title'),
				creator: cellData(currentItem.creators[0], 'No Creator'),
				country: cellData(currentItem.countryOfOrigin, 'No Country'),
				language: cellData(currentItem.bookInfo.language, 'N/A'),
				style: cellData(currentItem.bookInfo.style, ((currentItem.type === 'Book') ? 'No Style' : 'N/A')),
				DT_RowId: currentItem._id
			};
		});
	}

	render() {
		return (
			<div className="DatabaseView">
				<div className="position-relative">
					<AddEditItemModal key={this.props.currentUser._id} mode="add" currentUser={this.props.currentUser} onItemAdded={this.handleItemAdded} />		{/* key set to currentUser to force re-render when currentUser is updated (https://stackoverflow.com/a/48434525/2155673) */}
				</div>
				<div>
					<DataTable columns={this.tableColumns()} data={this.tableData()} onRowClick={this.handleItemRowClick} />
				</div>
			</div>
		);
	}
}

export default withRouter(DatabaseView);