/* *** Fevre River Database *** */
/* (C) 2022 - Joti Software, LLC */

// File Block Component //

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faFileAudio, faFileImage, faFileLines, faFilePdf, faFilePowerpoint, faFileExcel, faFileVideo, faFileWord, faFileZipper } from '@fortawesome/free-regular-svg-icons';

import Tooltip from './Tooltip';

import './FileBlock.css';

/*
	// Example:

	import FileBlock from './FileBlock';
	
	<FileBlock fileName={fileName} fileRef={fileRef} />

	// Available Props:
		- fileName: file name to display
			--Default: null
		- fileRef: full http link to file on server
			--Default: null
*/

function FileBlock(props) {
	const fileExt = props.fileRef.split('.').slice(-1)[0].toLowerCase();
	let fileIcon;
	switch(fileExt) {
		// archive
		case 'zip':
		case 'zipx':
		case '7z':
		case 'rar':
			fileIcon = faFileZipper;
			break;
		// document
		case 'doc':
		case 'docx':
		case 'pages':
			fileIcon = faFileWord;
			break;
		// image
		case 'jpg':
		case 'jpeg':
		case 'png':
		case 'gif':
		case 'tiff':
		case 'raw':
			fileIcon = faFileImage;
			break;
		// music
		case 'mp3':
		case 'mpeg3':
		case 'aac':
		case 'wav':
		case 'aiff':
		case 'wma':
		case 'flac':
		case 'ogg':
			fileIcon = faFileAudio;
			break;
		// pdf
		case 'pdf':
			fileIcon = faFilePdf;
			break;
		// presentation
		case 'ppt':
		case 'pptx':
		case 'key':
		case 'keynote':
			fileIcon = faFilePowerpoint;
			break;
		// spreadsheet
		case 'xls':
		case 'xlsx':
		case 'numbers':
			fileIcon = faFileExcel;
			break;
		// video
		case 'mp4':
		case 'mpeg4':
		case 'avi':
		case 'mov':
		case 'wmv':
		case 'mkv':
		case 'webm':
			fileIcon = faFileVideo;
			break;
		// text
		case 'txt':
			fileIcon = faFileLines;
			break;
		// default
		default:
			fileIcon = faFile;
	}
	return (
		<Tooltip placement="top" wrapperDisplay="block" title={props.fileName}>
			<a className="FileBlock" href={props.fileRef} rel="noreferrer" target="_blank"><FontAwesomeIcon className="me-2" icon={fileIcon}/>{props.fileName}</a>
		</Tooltip>
	);
}

export default FileBlock;