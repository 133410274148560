/* *** Fevre River Database *** */
/* (C) 2021 - Joti Software, LLC */

// Page Not Found (Error Page) View //

import React from 'react';
import { Link } from 'react-router-dom';

function PageNotFoundView(props) {
	return (
		<div className="position-absolute top-50 start-50 translate-middle text-center">
			<h3>Oops! Page not found.</h3>
			<h6 className="fw-light text-muted">Try going <Link to="/">Home</Link>, or try again later!</h6>
		</div>
	);
}

export default PageNotFoundView;