/* *** Fevre River Database *** */
/* (C) 2022 - Joti Software, LLC */

// Tooltip Wrapper Component //

import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

/*
	// Import as 'Tooltip' for simplicity
	// Example:

	import Tooltip from './Tooltip';
	
	<Tooltip placement="top|right|bottom|left" title="Example Tooltip Text!">
		<Button>Example Tooltip Target</Button>
	</Tooltip>

	// Available Props:
		- placement: follows react-bootstrap standards for placement of the tooltip
			--Default: 'top'
		- title: the actual content of the tooltip
			--Default: null
		- className: any className(s) to pass on to the wrapper element
			--Default: null
		- tooltipClassName: any className(s) to pass on to the internal <Tooltip> element
			--Default: null
		- uniqueId: unique identifier to use as key
			--Default: null
		- wrapperDisplay: certain uses require children to be wrapped in a container, so this sets the display property on the wrapper, to allow for consistent display depending on your use;
		 	uses standard bootstrap 'd-' display names - most common uses would be 'inline|block|inline-block'
		 	--Default: 'inline-block'
		- show: if false, displays child code without wrapping in a tooltip; allows easy on/off triggering of tooltip functionality based on another component's state
			--Default: true
*/

function TooltipWrapper(props) {
	const show = (props.show !== undefined) ? props.show : true;
	const placement = props.placement || 'top';
	const wrapperDisplay = 'd-' + (props.wrapperDisplay || 'inline-block');
	return (
		(show)
		?	<OverlayTrigger
				placement={placement}
				key={props.uniqueId}
				overlay={
					<Tooltip className={props.tooltipClassName}>
						{props.title}
					</Tooltip>
				}
			>
				<div className={`${wrapperDisplay} ${props.className}`}>{props.children}</div>
			</OverlayTrigger>
		:	props.children
	);
}

export default TooltipWrapper;