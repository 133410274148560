/* *** Fevre River Database *** */
/* (C) 2022 - Joti Software, LLC */

// Main App Header Component //

import React from 'react';
import { Container, Navbar, Nav, NavItem, NavLink, Dropdown } from 'react-bootstrap';
import { Link, useRouteMatch } from 'react-router-dom';
import LogoutButton from './LogoutButton';
import Tooltip from './Tooltip';

import icon from '../icon.png';

import './Header.css';

const baseURI = process.env.REACT_APP_API_URI + '/';

function CustomNavLink(props) {
	const match = useRouteMatch({ path: props.to, exact: props.activeOnlyWhenExact });
	return (
		<Nav.Link href={props.to} className={`${(match) ? "active" : ""} ${props.className}`} onClick={props.onClick}>{props.children}</Nav.Link>
	);
}

function BasicHeader(props) {
	return (
		<Navbar id="Header" fixed="top" expand="lg" variant="dark" bg="dark">
			<Container fluid>
				<Navbar.Brand href="/">
					<img src={icon} height="38px" className="float-start" alt="Logo" />
					<div className="d-inline-block float-start mt-1 ms-3">Fevre River Packet Co.</div>
				</Navbar.Brand>
			</Container>
		</Navbar>
	);
}

function Header(props) {
	const fullName = ( props.currentUser.firstName + ' ' + props.currentUser.lastName );
	const profilePicRef = (props.currentUser.profilePicRef) ? baseURI + props.currentUser.profilePicRef : '';
	return (
		<Navbar id="Header" fixed="top" expand="lg" variant="dark" bg="dark">
			<Container fluid>
				<Navbar.Brand href="/">
					<img src={icon} height="38px" className="float-start" alt="Logo" />
					<div className="d-inline-block float-start mt-1 ms-3">Fevre River Packet Co.</div>
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="me-auto">
						<CustomNavLink to="/" activeOnlyWhenExact={true} onClick={props.handleNavClick}>Database</CustomNavLink>
						<CustomNavLink to="/incoming-books" onClick={props.handleNavClick}>Incoming Books</CustomNavLink>
						<CustomNavLink to="/detail" className={(useRouteMatch({ path: '/detail' })) ? '' : 'd-none'} onClick={props.handleNavClick}>Detail</CustomNavLink>
						<Tooltip placement="bottom" title="To Be Developed...">
							<CustomNavLink to="/reports" className="disabled" onClick={props.handleNavClick}>Reports</CustomNavLink>
						</Tooltip>
						<Tooltip placement="bottom" title="To Be Developed...">
							<CustomNavLink to="/admin" className="disabled" onClick={props.handleNavClick}>Admin</CustomNavLink>
						</Tooltip>
					</Nav>
				</Navbar.Collapse>
				<Dropdown as={NavItem} align="end" id="ProfileDropdown">
					<Dropdown.Toggle as={NavLink}>
						<img className="ProfilePic rounded-circle" src={profilePicRef} alt={fullName} />
					</Dropdown.Toggle>
					<Dropdown.Menu>
						<Dropdown.Item>
							<div className="ProfileDropdownCard p-2">
								<img className="align-top rounded-circle ProfilePic me-3" src={profilePicRef} alt="<Profile Photo>" />
								<div className="d-inline-block">
									<div><strong>{fullName}</strong></div>
									<div className="fw-light text-muted">{props.currentUser.email}</div>
									<Tooltip placement="bottom" title="To Be Developed...">
										<Link to="/profile" id="profile-button" className="btn btn-sm btn-outline-surprise disabled mt-3 mb-1" type="button">View Profile</Link>
									</Tooltip>
								</div>
							</div>
						</Dropdown.Item>
						<Dropdown.Divider />
						<Dropdown.Item>
							<LogoutButton variant="dark" size="sm" className="float-end mb-1" />
						</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
			</Container>
		</Navbar>
	);
}

export { BasicHeader };
export default Header;