/* *** Fevre River Database *** */
/* (C) 2021 - Joti Software, LLC */

// Color Block Components //

import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import produce from 'immer';

import Tooltip from './Tooltip';

import './ColorBlock.css';

// Color Block
// TODO: make small version for display (prop size="sm", + CSS)

function ColorBlock(props) {
	const selectedClass = (props.selected) ? 'ColorBlock--selected' : '';
	const variantClass = (props.variant) ? 'ColorBlock--' + props.variant : '';
	const label = props.option.label;
	const value = props.option.value;
	return (
		<Tooltip placement="top" title={`Primary Color: ${label}`}>
			<div className={`ColorBlock ColorBlock--${value} ${variantClass} ${selectedClass}`} value={value} onClick={props.onClick}></div>
		</Tooltip>
	);
}

// Color Block Picker
class ColorBlockPicker extends Component {
	constructor(props) {
		super(props);
		
		this.handleClick = this.handleClick.bind(this);

		this.state = {
			selectedOptions: [],
			action: {
				action: '',
				name: this.props.name,
				option: undefined
			}
		};
	}

	componentDidMount() {
		this.setState({ selectedOptions: this.props.value });
	}

	handleClick(clickedOption) {
		this.setState(produce(draftState => {
			const selectedValues = this.state.selectedOptions.map(o => o.value);
			const index = selectedValues.indexOf(clickedOption.value);
			if (index > -1) {
				draftState.action.action = 'deselect-option';
				draftState.selectedOptions.splice(index, 1);
			}
			else {
				draftState.action.action = 'select-option';
				draftState.selectedOptions.push(clickedOption);
			}
		}), () => {
			this.props.onChange(this.state.selectedOptions, this.state.action);
		});
	}

	colorBlocks() {
		const selectedValues = this.state.selectedOptions.map(o => o.value);
		return this.props.options.map((currentOption, i) => {
			const selected = selectedValues.includes(currentOption.value);
			const colorBlock = <ColorBlock key={currentOption.value} option={currentOption} selected={selected} onClick={() => this.handleClick(currentOption)} />;
			const spacer = <div key={`spacerAfter-${i}`} className="w-100"></div>;	// key using index is OK as elements are never rearranged; if rearranging functionality is ever added, consider a different implementation
			if ((i + 1) % this.props.rowLength === 0) {
				return [colorBlock, spacer];	// full-width spacer <div> forces break into new row every 'rowLength' color blocks
			}
			else {
				return colorBlock;
			}
		});
	}

	render() {
		return (
			<Form.Group className="mb-3" controlId={this.props.controlId}>
				<Form.Label className="d-block">{this.props.label}</Form.Label>
				<div className="border border-secondary rounded text-center p-2">
					{this.colorBlocks()}
				</div>
			</Form.Group>
		);
	}
}

export { ColorBlock };
export default ColorBlockPicker;