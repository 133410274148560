/* *** Fevre River Database *** */
/* (C) 2021 - Joti Software, LLC */

// Image Input Component //
// Shows a formatted image input box, and previews the selected image in the same space

import React from 'react';
import { Form } from 'react-bootstrap';

import './ImageInput.css';

/*
	// Usage Example:
	
	<ImageInput src={this.state.imageURI} onChange={this.handleChange} />

	// Available Props:
		- className: any className(s) to pass on to the internal <Tooltip> element
			--Default: null
		- src: source for preview image
			--Default: null
*/

function ImageInput(props) {
	const valid = (props.src !== undefined && props.src !== '' && props.src !== null);
	const hasImageClass = (valid) ? 'ImageInput--has-image' : '';
	const validationClass = (props.required) ? ((valid) ? 'ImageInput--is-valid' : 'ImageInput--is-invalid') : '';
	return(
		<Form.Group className={`ImageInput ${hasImageClass} ${validationClass} ${props.className}`}>
			<Form.Control type="file" accept="image/*" onChange={props.onChange} tabIndex={props.tabIndex} required={props.required} />
			<img src={props.src} alt={props.label}/>
		</Form.Group>
	);
}

export default ImageInput;