/* *** Fevre River Database *** */
/* (C) 2021 - Joti Software, LLC */

// React Select Component //

// Abstracted react-select component for easier user, cleaner markup, & to ensure styling consistency

import React from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

import './ReactSelect.css';

/*
	// Usage Example:

	<ReactSelect size="sm|md|lg" name="select" placeholder="Select" options={[x, y, z]} value={y} onChange={(selectedOption, action) => { console.log('Name: ' + action.name); console.log('Value: ' + selectedOption.value); }} isDisabled={true|false} />

	// Available Props:
		- size: follows react-bootstrap standards for sizing	// TODO: need CSS for 'lg' ('md' is default, 'sm' has been created)
			--Default: 'md'
		- variant: trigger 'dark' variant for darker input fields
			--Default: undefined
		- name: name for identifying input
			--Default: undefined
		- placeholder: label to display as input placeholder
			--Default: undefined
		- options: array of options values (transformation into {label: x, value: y} structure will be handled by component)
		 	--Default: undefined
		- value: currently selected value, if any (transformation into {label: x, value: y} structure will be handled by component)
		 	--Default: undefined
		- onChange: onChange handler to be called when option is selected; arguments passed in are: 'selectedOption' in the form of {label: 'label', value: 'value'}, and 'action' (use action.name to get the name of the input)
			--Default: undefined
		- isCreatable: true/false value indicating whether select allows input of new select options, or not
			--Default: false
		- isDisabled: true/false value indicating whether select is disabled or not
			--Default: false
		- required: true/false value indicating whether select should be validated or not
			--Default: false

		- className: any className(s) to pass on to the internal <Tooltip> element	// TODO
			--Default: undefined
*/

function ReactSelect(props) {
	// setup
	const customClass = 'ReactSelect';
	const options = (props.options) ? props.options.map(o => ({ label: o, value: o })) : null;
	const value = (props.value) ? { label: props.value, value: props.value } : null;
	const sizeClass = (props.size) ? customClass + '--size-' + props.size : '';
	const variantClass = (props.variant) ? customClass + '--' + props.variant : '';
	// form validation
	const valid = (value !== undefined && value !== '' && value !== null && !props.isInvalid);
	const validationClass = (props.required || props.isInvalid) ? ((valid && !props.isInvalid) ? customClass + '--is-valid' : customClass + '--is-invalid') : '';
	// classes
	const classNames = [customClass, sizeClass, variantClass, validationClass, props.className].join(' ');
	return (
		(props.isCreatable)
		?	<CreatableSelect
				{...props}
				className={classNames}
				classNamePrefix={customClass}
				options={options}
				value={value}
			/>
		:	<Select
				{...props}
				className={classNames}
				classNamePrefix={customClass}
				options={options}
				value={value}
			/>
	);
}

export default ReactSelect;