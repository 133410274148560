/* *** Fevre River Database *** */
/* (C) 2022 - Joti Software, LLC */

// Add/Edit Item Modal //

import React, { Component } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Button, Collapse, Col, FloatingLabel, Form, Modal, Row, Tab, Spinner, Tabs } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import produce from 'immer';
import axios from 'axios';

import EditableSelect from './EditableSelect';
import ColorBlockPicker from './ColorBlock';
import ImageInput from './ImageInput';
import LocationInputCard from './LocationCard';
import ReactSelect from './ReactSelect';
import Tooltip from './Tooltip';

let source;
const baseURI = process.env.REACT_APP_API_URI + '/';
const optionsURI = baseURI + 'options/';
const addItemURI = baseURI + 'items/add/';
const updateItemURI = baseURI + 'items/update/';
const addImageURI = baseURI + 'items/image/';

function SetInfoCheckbox(props) {
	const title = (props.value === true) ? props.checkedTitle : props.uncheckedTitle;
	return (
		<Tooltip placement="top" title={title}>
			<Form.Check className={props.className} type="checkbox" name={props.name} checked={props.value} onChange={props.onChange} disabled={props.disabled} tabIndex={props.tabIndex} />
		</Tooltip>
	);
}

class AddEditItemModal extends Component {
	constructor(props) {
		super(props);

		this.handleModalShow = this.handleModalShow.bind(this);
		this.handleModalClose = this.handleModalClose.bind(this);

		this.handleAddCoCreatorField = this.handleAddCoCreatorField.bind(this);
		this.handleAddCollectionSubTitleField = this.handleAddCollectionSubTitleField.bind(this);
		this.handleArrayFieldInputChange = this.handleArrayFieldInputChange.bind(this);
		this.handleArrayFieldSelectChange = this.handleArrayFieldSelectChange.bind(this);
		this.handleColorPickerChange = this.handleColorPickerChange.bind(this);
		this.handleImageInputChange = this.handleImageInputChange.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleSelectChange = this.handleSelectChange.bind(this);
		this.handleLocationInputChange = this.handleLocationInputChange.bind(this);
		this.handleTypeSelectChange = this.handleTypeSelectChange.bind(this);
		this.handlePropertyChange = this.handlePropertyChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleOptionsUpdated = this.handleOptionsUpdated.bind(this);

		this.coCreatorFields = this.coCreatorFields.bind(this);
		this.collectionSubTitleFields = this.collectionSubTitleFields.bind(this);

		this.state = this.getInitialState();

		source = axios.CancelToken.source();
	}

	getInitialState() {
		// persist options data
		const options = {
			options: (this.state !== undefined) ? this.state.options : {},
			// - general options
			colorOptions: (this.state !== undefined) ? this.state.colorOptions : [],
			countryOptions: (this.state !== undefined) ? this.state.countryOptions : [],
			creatorOptions: (this.state !== undefined) ? this.state.creatorOptions : [],
			mediumOptions: (this.state !== undefined) ? this.state.mediumOptions : [],
			typeOptions: (this.state !== undefined) ? this.state.typeOptions : [],
			// - book specific options
			bookCollectionOptions: (this.state !== undefined) ? this.state.bookCollectionOptions : [],
			genreOptions: (this.state !== undefined) ? this.state.genreOptions : [],
			languageOptions: (this.state !== undefined) ? this.state.languageOptions : [],
			publisherOptions: (this.state !== undefined) ? this.state.publisherOptions : [],
			styleOptions: (this.state !== undefined) ? this.state.styleOptions : [],
		};
		// persist addedBy info
		const addedBy = (this.state !== undefined) ? this.state.item.addedBy : '';

		return ({
			// ui state
			showModal: false,
			isSubmitting: false,
			submitButtonTitle: (this.props.mode === 'add') ? 'Add Item to Database' : 'Save Changes',
			coCreatorFieldCount: 0,
			collectionSubTitleFieldCount: 0,

			// select options
			options: options.options,
			// - general options
			colorOptions: options.colorOptions,
			countryOptions: options.countryOptions,
			creatorOptions: options.creatorOptions,
			mediumOptions: options.mediumOptions,
			typeOptions: options.typeOptions,
			// - book specific options
			bookCollectionOptions: options.bookCollectionOptions,
			genreOptions: options.genreOptions,
			languageOptions: options.languageOptions,
			publisherOptions: options.publisherOptions,
			styleOptions: options.styleOptions,

			creatorLabels: {	// TODO: store in options document
				"Book": 'Author',
				"Flat Art": 'Artist',
				"Sculpture": 'Artist',
				"Merchandise": 'Manufacturer',
				"Memorabilia": 'Manufacturer',
				"Miniature": 'Artist',
				"Award": 'Presenter'
			},

			// form data
			item: {
				hasImageUpload: false,	// set to let API know whether to return just ID for subsequent image upload, or return full item for rerender
				title: '',
				subTitle: '',
				creators: [],
				type: '',
				medium: '',
				location: {
					property: '',
					building: '',
					room: '',
					wall: '',
					case: '',
					shelf: '',
					positionX: '',
					positionY: '',
					notes: ''
				},
				bragLocation: {
					property: '',
					building: '',
					room: '',
					wall: '',
					case: '',
					shelf: '',
					positionX: '',
					positionY: '',
					notes: ''
				},
				otherLocation: {
					property: '',
					building: '',
					room: '',
					wall: '',
					case: '',
					shelf: '',
					positionX: '',
					positionY: '',
					notes: ''
				},
				quantity: '',
				countryOfOrigin: '',
				primaryColors: [],
				dimensions: {
					length: '',
					width: '',
					height: ''
				},
				appraisalInfo: null,		// TODO
				provenanceInfo: null,		// TODO
				miscDocRefs: [],
				notes: [],
				addMethod: 'Manual',
				addedBy: addedBy,
				editHistory: [],
				linkedItems: [],
				bookInfo: {
					genre: '',
					style: '',
					subTitle: '',
					collectionSubTitles: [],
					isbn: '',
					publisher: '',
					dateOfPublication: '',
					language: '',
					bookCollection: '',
					setInfo: {
						primeCopyReceived: false,
						primeCopyReceivedDate: '',

						texasAMCopyReceived: false,
						texasAMCopyReceivedDate: '',
						texasAMCopySent: false,
						texasAMCopySentDate: '',

						leslieCopyReceived: false,
						leslieCopyReceivedDate: '',
						leslieCopySent: false,
						leslieCopySentDate: '',

						bragCopyReceived: false,
						bragCopyReceivedDate: '',

						otherCopyReceived: false
					}
				}
			},
			formValidated: false,
			isUnedited: (this.props.mode === 'edit'),
			editedProperties: {},

			// temp storage for selected image
			imageURI: null,
			imageData: null,

			// used to temporarily store book info, if editor is switched away from book mode (in case user switches back)
			tempBookInfo: {
					genre: '',
					style: '',
					subTitle: '',
					collectionSubTitles: [],
					isbn: '',
					publisher: '',
					dateOfPublication: '',
					language: '',
					bookCollection: '',
					setInfo: {
						primeCopyReceived: false,
						primeCopyReceivedDate: '',

						texasAMCopyReceived: false,
						texasAMCopyReceivedDate: '',
						texasAMCopySent: false,
						texasAMCopySentDate: '',

						leslieCopyReceived: false,
						leslieCopyReceivedDate: '',
						leslieCopySent: false,
						leslieCopySentDate: '',

						bragCopyReceived: false,
						bragCopyReceivedDate: '',

						otherCopyReceived: false
					}
			},

			// used to temporarily store *not* book info, if editor is switched into book mode (in case user switches back)
			tempOtherInfo: {
				primaryColors: [],
				dimensions: {
					length: '',
					width: '',
					height: ''
				}
			}
		});
	}
	
	componentDidMount() {
		// get option presets for select inputs
		axios
			.get(optionsURI, {
				cancelToken: source.token
        	})
			.then(response => {
				const options = response.data[0];
				this.setState({ options: options });
				// - general options
				this.setState({ colorOptions: options.colors });
				this.setState({ countryOptions: options.countries });
				this.setState({ creatorOptions: options.creators });
				this.setState({ typeOptions: Object.keys(options.types) });
				if (this.props.item) this.setState({ mediumOptions: options.types[this.props.item.type] });
				// - book specific options
				this.setState({ bookCollectionOptions: options.bookOptions.bookCollections });
				this.setState({ genreOptions: options.bookOptions.genres });
				this.setState({ languageOptions: options.bookOptions.languages });
				this.setState({ publisherOptions: options.bookOptions.publishers });
				this.setState({ styleOptions: options.bookOptions.styles });
			})
			.catch(err => this.handleError(err, 'Error getting select options from DB.'));

		// set item & related fields
		if (this.props.mode === 'edit') {
			this.setState({ item: this.props.item }, () => {
				// set creator field count
				const creatorLength = this.state.item.creators.length;
				const coCreatorCount = (creatorLength > 1) ? creatorLength - 1 : 0;
				// set collectionSubTitle field count
				const collectionSubTitleLength = this.state.item.bookInfo.collectionSubTitles.length;
				const collectionSubTitleCount = (collectionSubTitleLength > 1) ? collectionSubTitleLength - 1 : 0;

				this.setState({ coCreatorFieldCount: coCreatorCount, collectionSubTitleFieldCount: collectionSubTitleCount, imageURI: baseURI+this.state.item.imageRef });
			});
		}

		// set item addedBy to current logged in user
		if (this.props.mode === 'add') {
			this.setState(produce(draftState => { draftState.item.addedBy = this.props.currentUser._id }));
		}
	}

	componentWillUnmount() {
		// cancel axios requests if component is unmounted
		if (source) {
			// source.cancel('AddEditItemModal Component was unmounted');	// TODO: disabled until error leaving options unset is found
		}
	}

	handleModalShow(props) {
		this.setState({ showModal: true });
	}

	handleModalClose(props) {
		this.setState({ showModal: false });
	}

	// Handle click on co-creator field 'add' (plus) button
	handleAddCoCreatorField() {
		this.setState(prevState => ({ coCreatorFieldCount: prevState.coCreatorFieldCount + 1 }));
	}

	// Handle click on collection sub-title field 'add' (plus) button
	handleAddCollectionSubTitleField() {
		this.setState(prevState => ({ collectionSubTitleFieldCount: prevState.collectionSubTitleFieldCount + 1 }));
	}

	// Handle changes for 'input' elements
	handleInputChange(e) {
		const name = e.target.name;
		const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

		if (name.includes('.')) {		// Handle nested state values (TODO: update to hande N number of nested state values)
			const names = name.split('.');
			if (names.length === 2) {
				this.setState(produce(draftState => { draftState.item[names[0]][names[1]] = value }));
			}
			else if (names.length === 3){
				this.setState(produce(draftState => { draftState.item[names[0]][names[1]][names[2]] = value }));
			}
		}
		else {							// Handle regular state values
			this.setState(produce(draftState => { draftState.item[name] = value }));
		}
		this.handlePropertyChange(name, value);
	}

	// Handle changes for 'select' elements
	handleSelectChange(selectedOption, action) {
		const name = action.name;
		const value = selectedOption.value;

		if (name.includes('.')) {		// Handle nested state values
			const names = name.split('.');
			this.setState(produce(draftState => { draftState.item[names[0]][names[1]] = value }));
		}
		else {							// Handle regular state values
			this.setState(produce(draftState => { draftState.item[name] = value }));
		}
		this.handlePropertyChange(name, value);
	}

	// Handle changes for 'type' select (separate code to handle added complexity)
	handleTypeSelectChange(selectedOption) {
		const selectedType = selectedOption.value;

		// If type is changed away from/to book, clear data for hidden inputs (and temporarily store in case user switches back)
		/* TODO: improve this code, as the use case is growing, and storing state/item structure in multiple places and using lots of logic to handle UI changes is poor design & difficult to maintain */
		if (selectedType !== 'Book') {
			// store hidden book info, replace any temporary other info from previous change
			const emptyBookInfo = {
				genre: '',
				style: '',
				subTitle: '',
				collectionSubTitles: [],
				isbn: '',
				publisher: '',
				dateOfPublication: '',
				language: '',
				bookCollection: '',
				setInfo: {
					primeCopyReceived: false,
					primeCopyReceivedDate: '',

					texasAMCopyReceived: false,
					texasAMCopyReceivedDate: '',
					texasAMCopySent: false,
					texasAMCopySentDate: '',

					leslieCopyReceived: false,
					leslieCopyReceivedDate: '',
					leslieCopySent: false,
					leslieCopySentDate: '',

					bragCopyReceived: false,
					bragCopyReceivedDate: '',

					otherCopyReceived: false
				}
			};
			this.setState(prevState => ({ tempBookInfo: prevState.item.bookInfo }));
			this.setState(produce(draftState => { draftState.item.bookInfo = emptyBookInfo }));

			// restore temporarily stored other info
			if (this.state.item.type === 'Book') {									// don't overwrite other info unless type is changing from book
				this.setState(produce(draftState => {
					draftState.item.primaryColors = draftState.tempOtherInfo.primaryColors;
					draftState.item.dimensions = draftState.tempOtherInfo.dimensions;
				}));
			}
		}
		else {
			// if type is changed *to* book, clear not-book data (and temporarily store in case user switches back)
			const emptyOtherInfo = {
				primaryColors: [],
				dimensions: {
					length: '',
					width: '',
					height: ''
				}
			};
			this.setState(produce(draftState => {
				draftState.tempOtherInfo.primaryColors = draftState.item.primaryColors;
				draftState.tempOtherInfo.dimensions = draftState.item.dimensions;
			}));
			this.setState(produce(draftState => {
				draftState.item.primaryColors = emptyOtherInfo.primaryColors;
				draftState.item.dimensions = emptyOtherInfo.dimensions;
			}));
			
			// restore temporarily stored book info
			if (this.state.item.type !== 'Book') {	// edge case: don't accidentally overwrite new bookInfo if type is already book
				this.setState(produce(draftState => { draftState.item.bookInfo = draftState.tempBookInfo }));
			}
		}

		// Remove 'medium' select value (don't save value from previous sub-list, if new parent 'type' value is chosen)
		if (selectedType !== this.state.item.type) {
			this.setState(produce(draftState => { draftState.item.medium = null }));
		}

		// Save type to state
		this.setState(produce(draftState => { draftState.item.type = selectedType }));

		// Setup sub-list options for 'medium' select
		this.setState({ mediumOptions: this.state.options.types[selectedType] });

		this.handlePropertyChange('type', selectedType);
	}

	// Handle changes for array field input elements
	handleArrayFieldInputChange(e) {
		const names = e.target.name.split('.');
		const index = names[names.length - 1];
		const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

		if (names.length === 3) {		// Handle nested state values (TODO: update to hande N number of nested state values)
			this.setState(produce(draftState => {
				const propertyArray = draftState.item[names[0]][names[1]]
				const replaceExisting = (propertyArray[index] && propertyArray[index].length) ? 1 : 0;
				propertyArray.splice(index, replaceExisting, value);
			}));
		}
		else {							// Handle regular state values
			this.setState(produce(draftState => { draftState.item[names[0]][index] = value }));
		}

		this.handlePropertyChange(e.target.name, value);
	}

	// Handle changes for array field select elements
	handleArrayFieldSelectChange(selectedOption, action) {
		const names = action.name.split('.');
		const index = names[names.length - 1];
		const value = selectedOption.value;

		if (names.length === 3) {		// Handle nested state values (TODO: update to hande N number of nested state values)
			this.setState(produce(draftState => {
				const propertyArray = draftState.item[names[0]][names[1]]
				const replaceExisting = (propertyArray[index] && propertyArray[index].length) ? 1 : 0;
				propertyArray.splice(index, replaceExisting, value);
			}));
		}
		else {							// Handle regular state values
			this.setState(produce(draftState => { draftState.item[names[0]][index] = value }));
		}

		this.handlePropertyChange(action.name, value);
	}

	// Handle changes for 'ImageInput' elements
	handleImageInputChange(e) {
		if (e.target.files && e.target.files[0]) {
			// setup image preview
			let reader = new FileReader();
			reader.onload = function(ev) {
				this.setState({ imageURI: ev.target.result });
			}.bind(this);
			reader.readAsDataURL(e.target.files[0]);

			// setup image state
			this.setState(produce(draftState => {
				draftState.item.hasImageUpload = true;
				draftState.imageData = e.target.files[0];
				this.handlePropertyChange('imageRef', '<new-image>');
			}));
		}
	}

	// Handle changes for 'ColorBlockPicker' elements
	handleColorPickerChange(selectedOptions, action) {
		const name = action.name;
		this.setState(produce(draftState => { draftState.item[name] = selectedOptions }));
		this.handlePropertyChange(name, selectedOptions);
	}

	// Handle changes for the 'LocationInputCard' element
	handleLocationInputChange(location, name) {
		this.setState(produce(draftState => { draftState.item[name] = location }));
		this.handlePropertyChange(name, location);
	}

	// Handle any property changed
	handlePropertyChange(propertyName, propertyValue) {
		if (this.props.mode === 'edit') {
			this.setState(produce(draftState => {
				draftState.isUnedited = false;
				draftState.editedProperties[propertyName] = propertyValue;
			}));
		}
	}

	// Handle options updated (by EditableSelect inputs)
	handleOptionsUpdated(newOptions, newOptionsKeyName) {
		this.setState(produce(draftState => { draftState[newOptionsKeyName] = newOptions }));
	}

	// Handle form submit
	handleSubmit(e) {
		// temp for dev
		console.group('Item Submitted:');
		console.log(this.state.item);
		console.groupEnd();

		// TODO: prevent submit on 'Enter' key press (https://github.com/react-hook-form/react-hook-form/discussions/2549#discussioncomment-143178)
		e.preventDefault();

		// check validity - don't submit if invalid
		// - hacky way to check if all select inputs are valid -- investigate better solutions-
    	const form = e.currentTarget;
    	const item = this.state.item;
    	const checkSelectValidity = (item) => {
    		// check validity of select elements
			const requiredSelectValues = [
				item.type,
				item.medium,
				item.creators[0],
				item.quantity,
				item.countryOfOrigin,
				item.location.property,
				item.location.building,
				item.location.room
			];
			const requiredBookInfoSelectValues = [
				item.bookInfo.isbn,
				item.bookInfo.dateOfPublication,
				item.bookInfo.bookCollection
			];
			const requiredBragLocationSelectValues = [
				item.bragLocation.property,
				item.bragLocation.building,
				item.bragLocation.room
			];
			const requiredOtherLocationSelectValues = [
				item.otherLocation.property,
				item.otherLocation.building,
				item.otherLocation.room
			];
			if (item.type === 'Book') {
				requiredSelectValues.push(...requiredBookInfoSelectValues);
			}
			if (item.bookInfo.setInfo.bragCopyReceived) {
				requiredSelectValues.push(...requiredBragLocationSelectValues);
			}
			if (item.bookInfo.setInfo.otherCopyReceived) {
				requiredSelectValues.push(...requiredOtherLocationSelectValues);
			}
    		for (const currentValue of requiredSelectValues) {
    			if (currentValue === undefined || currentValue === '' || currentValue === null) {
    				return false
    			}
    		}
    		// check validity of location position
    		const location = item.location;
    		const invalidMissingX = (location.positionY && !location.positionX);
			const invalidMissingY = (location.positionX && !location.positionY);
			const invalidXGreaterThanY = (Number(location.positionX) > Number(location.positionY));
			if (invalidMissingX || invalidMissingY || invalidXGreaterThanY) {
				return false;
			}
			// everything is valid!
    		return true;
    	};
		if (form.checkValidity() === false || checkSelectValidity(item) === false) {
			// form is invalid, don't submit
			e.preventDefault();
			e.stopPropagation();
			// scroll first invalid input into view
			const invalidInputs = Array.from(form.querySelectorAll(':invalid, .ReactSelect--is-invalid'));
			invalidInputs.sort((a, b) => a.getBoundingClientRect().top - b.getBoundingClientRect().top);	// sort inputs by offset from top of viewport
			invalidInputs[0].scrollIntoView({ block: 'center', behavior: 'smooth' });
		}
		else {
			// submit form data
			const handleItemAddedOrUpdated = (item) => {
				// reset view & call change handler
				this.setState({ isSubmitting: false, submitButtonTitle: 'Done!' });
				if (this.props.mode === 'add') {
					this.props.onItemAdded(item);  
					this.setState(this.getInitialState);
				}
				else {
					this.props.onItemUpdated(item);
					this.handleModalClose();
					this.setState({ item: item, formValidated: false, isUnedited: false, editedProperties: {}, submitButtonTitle: 'Save Changes' });
				}
			};
			this.setState({ isSubmitting: true, submitButtonTitle: 'Loading...' }, () => {
				let item = JSON.parse(JSON.stringify(this.state.item));
				if (this.props.mode === 'edit') {
					const editHistoryRecord = { editedBy: this.props.currentUser._id, editedAt: new Date().toISOString(), editedProperties: this.state.editedProperties };
					item.editHistory.push(editHistoryRecord);
				}
				const URI = (this.props.mode === 'add') ? addItemURI : updateItemURI+item._id;
				axios.post(URI, item)
					.then(response => {
						if (this.state.item.hasImageUpload) {
							// submit image
							const itemId = response.data;
							const formData = new FormData();
							formData.append('itemId', itemId);
							formData.append('image', this.state.imageData);
							this.setState({ submitButtonTitle: 'Uploading Image...' });
							axios.post(addImageURI+itemId, formData)
								.then(response => {
									handleItemAddedOrUpdated(response.data);
								})
								.catch(err => this.handleError(err, 'Error uploading item image.'));
						}
						else {
							handleItemAddedOrUpdated(response.data);
						}
					})
					.catch(err => this.handleError(err, 'Error submitting valid item form to DB.'));
			});
		}
		this.setState({ formValidated: true });
	}

	handleError(error, callingAction) {
		if (axios.isCancel(error)) {
			console.log(error);
		}
		else {
			const alertMessage = 'Oops! It looks like something went wrong. Please try again in a little while. Support info: [' + callingAction + ']';
			console.group('Error encountered:');
			console.log(callingAction);
			console.log(error);
			console.log('Current component state:');
			console.log(this.state);
			console.groupEnd();
			alert(alertMessage);
		}
	}

	coCreatorFields(creatorLabel) {
		const fields = [...Array(this.state.coCreatorFieldCount).keys()].map((currentItem, i) => {
			const creatorIndex = i + 1;
			const coCreatorLabel = 'Co-' + creatorLabel;
			const name = 'creators.' + creatorIndex;
			return (
				<CSSTransition key={name} timeout={500} classNames="slide-in">
					<div className="mb-3">
						<EditableSelect name={name} placeholder={coCreatorLabel} options={this.state.creatorOptions} value={this.state.item.creators[creatorIndex]} onChange={this.handleArrayFieldSelectChange} onOptionsUpdated={this.handleOptionsUpdated} onError={this.handleError} updateKeyName="creators" />
						<Form.Control.Feedback type="invalid">Please enter a {coCreatorLabel}.</Form.Control.Feedback>
					</div>
				</CSSTransition>
			);
		});
		return fields;
	}

	collectionSubTitleFields() {
		const fields = [...Array(this.state.collectionSubTitleFieldCount).keys()].map((currentItem, i) => {
			const collectionSubTitleIndex = i + 1;
			const name = 'bookInfo.collectionSubTitles.' + collectionSubTitleIndex;
			return (
				<CSSTransition key={name} timeout={500} classNames="slide-in">
					<FloatingLabel label="Collection Sub-title" className="mb-3">
						<Form.Control type="text" name={name} placeholder="Collection Sub-title" defaultValue={this.state.item.bookInfo.collectionSubTitles[collectionSubTitleIndex]} onChange={this.handleArrayFieldInputChange} />
					</FloatingLabel>
				</CSSTransition>
			);
		});
		return fields;
	}

	formatDate(isoDate) {
		return (isoDate) ? isoDate.split("T")[0] : '';
	}

	render() {
		const creatorLabel = this.state.creatorLabels[this.state.item.type] || 'Creator';
		const styleSubTitleLabel = (this.state.item.bookInfo.style === 'Anthology' || this.state.item.bookInfo.style === 'Collaborative') ? this.state.item.bookInfo.style + ' Sub-title' : '';
		return (
			<>
				{/* *** Add/Edit Item Button *** */}
				{(this.props.mode === 'add')
					? <Button variant="outline-surprise" size="sm" className={`AddItem__button ${this.props.buttonClassName}`} onClick={this.handleModalShow}><FontAwesomeIcon className="me-1" icon={faPlus}/> Add Item</Button>
					: <Button variant="outline-primary" size="sm" className={`EditItem__button ${this.props.buttonClassName}`} onClick={this.handleModalShow}><FontAwesomeIcon className="me-1" icon={faPenToSquare}/> Edit Item</Button>
				}

				{/* *** Add/Edit Item Modal ***  */}
				<Modal show={this.state.showModal} onHide={this.handleModalClose} size="xl">
					<Form noValidate validated={this.state.formValidated} onSubmit={this.handleSubmit}>
						<Modal.Header closeButton>
							<Modal.Title>{(this.props.mode === 'add') ? 'Add' : 'Edit'} Item</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<Row>
								<Col className="AddEditItemImage__Col">
	{/* Image */}
									<Row>
										<Col>
											<div className="mb-4">
												<ImageInput src={this.state.imageURI} onChange={this.handleImageInputChange} tabIndex="1" required={this.props.mode === 'add'} />
												<Form.Control.Feedback type="invalid">Please select an image.</Form.Control.Feedback>
											</div>
										</Col>
									</Row>
	{/* Physical Info */}
									<h4 className="fw-light mb-3">Physical Info:</h4>
									<Row>
										<Col md="4">
											<div className="mb-3">
												<ReactSelect name="quantity" placeholder="Qty." options={[...Array(51).keys()].slice(1)/*create list of numbers from 1 to 50*/} value={this.state.item.quantity} onChange={this.handleSelectChange} tabIndex="6" required />
												<Form.Control.Feedback type="invalid">Please select a quantity.</Form.Control.Feedback>
											</div>
										</Col>
										<Col>
											<div className="mb-3">
												<EditableSelect name="countryOfOrigin" placeholder="Country of Origin" options={this.state.countryOptions} value={this.state.item.countryOfOrigin} onChange={this.handleSelectChange} onOptionsUpdated={this.handleOptionsUpdated} onError={this.handleError} updateKeyName="countries" tabIndex="7" required />
												<Form.Control.Feedback type="invalid">Please enter a country.</Form.Control.Feedback>
											</div>
										</Col>
									</Row>
									<Collapse in={(this.state.item.type !== 'Book')}>
										<div>
											<Form.Group className="mb-3">
												<Row>
													<Form.Label>Dimensions (inches):</Form.Label>
													<Col md="4">
														<FloatingLabel label="Length">
															<Form.Control type="text" name="dimensions.length" placeholder="Length" defaultValue={this.state.item.dimensions.length} onChange={this.handleInputChange} tabIndex="8" />
														</FloatingLabel>
													</Col>
													<Col md="4">
														<FloatingLabel label="Width">
															<Form.Control type="text" name="dimensions.width" placeholder="Width" defaultValue={this.state.item.dimensions.width} onChange={this.handleInputChange} tabIndex="9" />
														</FloatingLabel>
													</Col>
													<Col md="4">
														<FloatingLabel label="Height">
															<Form.Control type="text" name="dimensions.height" placeholder="Height" defaultValue={this.state.item.dimensions.height} onChange={this.handleInputChange} tabIndex="10" />
														</FloatingLabel>
													</Col>
												</Row>		
											</Form.Group>
											<Row>
												<Col>
													<ColorBlockPicker label="Primary Color(s):" name="primaryColors" rowLength="6" options={this.state.colorOptions} value={this.state.item.primaryColors} onChange={this.handleColorPickerChange} />
												</Col>
											</Row>
										</div>
									</Collapse>
									<Row>
										<Col>
											<Tooltip placement="bottom" title="To Be Developed...">
												<Form.Group className="mb-3">
													<Form.Label>Additional Documents:</Form.Label>
														<Form.Control type="file" multiple disabled />
												</Form.Group>
											</Tooltip>
										</Col>
									</Row>
								</Col>
								<Col>
									<Tabs defaultActiveKey="general-tab" id="add-edit-item-modal-tabs" className="mb-3">		{/* TODO: add "To Be Developed..." tooltips to disabled tab headers */}
{/* --General Info Tab-- */}
										<Tab eventKey="general-tab" title="General Info" tabIndex="-1">
	{/* Item Info */}
											<Row>
												<Col>
													<h4 className="fw-light mb-3">Item Info:</h4>
												</Col>
											</Row>
											<Row className="mb-2">
												<Col>
													<div className="mb-3">
														<ReactSelect name="type" placeholder="Type" options={this.state.typeOptions} value={this.state.item.type} onChange={this.handleTypeSelectChange} tabIndex="2" required />
														<Form.Control.Feedback type="invalid">Please select a type.</Form.Control.Feedback>
													</div>
												</Col>
												<Col>
													<Tooltip wrapperDisplay="block" show={!this.state.item.type} title="Select a Type">
														<div className="mb-3">
															<ReactSelect name="medium" placeholder="Medium" options={this.state.mediumOptions} value={this.state.item.medium} onChange={this.handleSelectChange} isDisabled={!this.state.item.type} tabIndex="3" required />
															<Form.Control.Feedback type="invalid">Please select a medium.</Form.Control.Feedback>
														</div>
													</Tooltip>
												</Col>
											</Row>
											<Row>
												<Col>
													<FloatingLabel label="Title" className="mb-3">
														<Form.Control type="text" name="title" placeholder="Title" defaultValue={this.state.item.title} onChange={this.handleInputChange} tabIndex="4" required />
														<Form.Control.Feedback type="invalid">Please enter a title.</Form.Control.Feedback>
													</FloatingLabel>
													<Collapse in={(this.state.item.medium === 'Magazine')}>
														<FloatingLabel label="Sub-title" className="mb-3">
															<Form.Control type="text" name="subTitle" placeholder="Sub-title" defaultValue={this.state.item.subTitle} onChange={this.handleInputChange} tabIndex="4" />
															<Form.Control.Feedback type="invalid">Please enter a sub-title.</Form.Control.Feedback>
														</FloatingLabel>
													</Collapse>
													<Row className="gx-3 mb-3">
														<Col>
															<EditableSelect name="creators.0" placeholder={creatorLabel} options={this.state.creatorOptions} value={this.state.item.creators[0]} onChange={this.handleArrayFieldSelectChange} onOptionsUpdated={this.handleOptionsUpdated} onError={this.handleError} updateKeyName="creators" tabIndex="5" required />
															<Form.Control.Feedback type="invalid">Please enter a{(creatorLabel.startsWith('A')) ? 'n' : ''} {creatorLabel}.</Form.Control.Feedback>
														</Col>
														<Col md="auto">
															<Tooltip placement="top" title={`Add Co-${creatorLabel}`}>
																<Button variant="outline-input-match" onClick={this.handleAddCoCreatorField}><FontAwesomeIcon icon={faPlus}/></Button>
															</Tooltip>
														</Col>
													</Row>
													<TransitionGroup>
														{this.coCreatorFields(creatorLabel)}
													</TransitionGroup>
	{/* Book Info */}
													<Collapse in={(this.state.item.type === 'Book')}>
														<div>
															<h4 className="fw-light mb-3">Book Info:</h4>
															<ReactSelect className="mb-3" name="bookInfo.style" placeholder="Style" options={this.state.styleOptions} value={this.state.item.bookInfo.style} onChange={this.handleSelectChange} tabIndex="11" />
															<Collapse in={(this.state.item.bookInfo.style === 'Anthology' || this.state.item.bookInfo.style === 'Collaborative')}>
																<FloatingLabel label={styleSubTitleLabel} className="mb-3">
																	<Form.Control type="text" name="bookInfo.subTitle" placeholder={styleSubTitleLabel} defaultValue={this.state.item.bookInfo.subTitle} onChange={this.handleInputChange} tabIndex="12" required={this.state.item.bookInfo.style === 'Anthology' || this.state.item.bookInfo.style === 'Collaborative'} />
																	<Form.Control.Feedback type="invalid">Please enter a{(this.state.item.bookInfo.style.startsWith('A')) ? 'n' : ''} {this.state.item.bookInfo.style} sub-title.</Form.Control.Feedback>
																</FloatingLabel>
															</Collapse>
															<Collapse in={(this.state.item.bookInfo.style === 'Collection')}>
																<div>
																	<Row className="gx-3 mb-3">
																		<Col>
																			<FloatingLabel label="Collection Sub-title">
																				<Form.Control type="text" name="bookInfo.collectionSubTitles.0" placeholder="Collection Sub-title" defaultValue={this.state.item.bookInfo.collectionSubTitles[0]} onChange={this.handleArrayFieldInputChange} />
																			</FloatingLabel>
																		</Col>
																		<Col md="auto" className="d-flex align-items-center">
																			<Tooltip placement="top" title="Add Collection Sub-title">
																				<Button variant="outline-input-match" onClick={this.handleAddCollectionSubTitleField}><FontAwesomeIcon icon={faPlus}/></Button>
																			</Tooltip>
																		</Col>
																	</Row>
																	<TransitionGroup>
																		{this.collectionSubTitleFields()}
																	</TransitionGroup>
																</div>
															</Collapse>
															<EditableSelect className="mb-3" name="bookInfo.publisher" placeholder="Publisher" options={this.state.publisherOptions} value={this.state.item.bookInfo.publisher} onChange={this.handleSelectChange} onOptionsUpdated={this.handleOptionsUpdated} onError={this.handleError} updateKeyName="bookOptions.publishers" tabIndex="13" />
															<EditableSelect className="mb-3" name="bookInfo.genre" placeholder="Genre" options={this.state.genreOptions} value={this.state.item.bookInfo.genre} onChange={this.handleSelectChange} onOptionsUpdated={this.handleOptionsUpdated} onError={this.handleError} updateKeyName="bookOptions.genres" tabIndex="14" />
															<EditableSelect className="mb-3" name="bookInfo.language" placeholder="Language" options={this.state.languageOptions} value={this.state.item.bookInfo.language} onChange={this.handleSelectChange} onOptionsUpdated={this.handleOptionsUpdated} onError={this.handleError} updateKeyName="bookOptions.languages" tabIndex="15" required />
															<Row>
																<Col>
																	<FloatingLabel label="ISBN" className="mb-3">
																		<Form.Control type="text" name="bookInfo.isbn" placeholder="ISBN" defaultValue={this.state.item.bookInfo.isbn} onChange={this.handleInputChange} tabIndex="16" required={this.state.item.type === 'Book'} />
																		<Form.Control.Feedback type="invalid">Please enter an ISBN number.</Form.Control.Feedback>
																	</FloatingLabel>
																</Col>
																<Col>
																	<FloatingLabel label="Date of Publication" className="mb-3">
																		<Form.Control type="text" name="bookInfo.dateOfPublication" placeholder="Date of Publication" defaultValue={this.state.item.bookInfo.dateOfPublication} onChange={this.handleInputChange} tabIndex="17" required={this.state.item.type === 'Book'} />
																		<Form.Control.Feedback type="invalid">Please enter a date of publication.</Form.Control.Feedback>
																	</FloatingLabel>
																</Col>
															</Row>
															<div className="mb-3">
																<ReactSelect name="bookInfo.bookCollection" placeholder="Collection" options={this.state.bookCollectionOptions} value={this.state.item.bookInfo.bookCollection} onChange={this.handleSelectChange} tabIndex="18" required={this.state.item.type === 'Book'} />
																<Form.Control.Feedback type="invalid">Please select a collection.</Form.Control.Feedback>
															</div>
														</div> 
													</Collapse>
	{/* Business Collection Info */}
													<Collapse in={(this.state.item.bookInfo.bookCollection === 'Business')}>
														<div>
															<h4 className="fw-light mb-3">Business Collection Info:</h4>
															<Row className="mx-0 mb-3 text-center">
																<Col md="2"></Col>
																<Col md="5">
																	<span className="fs-5 text-muted">Received:</span>
																</Col>
																<Col md="5">
																	<span className="fs-5 text-muted">Sent:</span>
																</Col>
															</Row>
															<Row className="align-items-center mx-0 pb-3 border-bottom border-secondary border-top border-top-clear">
																<Col md="2">
																	<Form.Label className="mb-0">Prime:</Form.Label>
																</Col>
																<Col md="5">
																	<Row>
																		<Col md="2"><SetInfoCheckbox className="mt-2" name="bookInfo.setInfo.primeCopyReceived" checkedTitle="Unmark Prime Copy Received" uncheckedTitle="Mark Prime Copy Received" value={this.state.item.bookInfo.setInfo.primeCopyReceived} onChange={this.handleInputChange} tabIndex="28" /></Col>
																		<Col md="10"><Form.Control className={(this.state.item.bookInfo.setInfo.primeCopyReceivedDate) ? 'has-value' : ''} type="date" name="bookInfo.setInfo.primeCopyReceivedDate" value={this.formatDate(this.state.item.bookInfo.setInfo.primeCopyReceivedDate)} onChange={this.handleInputChange} tabIndex="29" /></Col>
																	</Row>
																</Col>
																<Col md="5">
																	<Tooltip placement="bottom" title="Not Applicable">
																		<Row>
																			<Col md="2"><Form.Check className="mt-2" disabled /></Col>
																			<Col md="10"><Form.Control type="date" disabled /></Col>
																		</Row>
																	</Tooltip>
																</Col>
															</Row>
															<Row className="align-items-center mx-0 py-3 border-bottom border-secondary border-top border-top-clear">
																<Col md="2">
																	<Form.Label className="mb-0">A&M:</Form.Label>
																</Col>
																<Col md="5">
																	<Row>
																		<Col md="2"><SetInfoCheckbox className="mt-2" name="bookInfo.setInfo.texasAMCopyReceived" checkedTitle="Unmark Texas A&M Copy Received" uncheckedTitle="Mark Texas A&M Copy Received" value={this.state.item.bookInfo.setInfo.texasAMCopyReceived} onChange={this.handleInputChange} tabIndex="30" /></Col>
																		<Col md="10"><Form.Control className={(this.state.item.bookInfo.setInfo.texasAMCopyReceivedDate) ? 'has-value' : ''} type="date" name="bookInfo.setInfo.texasAMCopyReceivedDate" value={this.formatDate(this.state.item.bookInfo.setInfo.texasAMCopyReceivedDate)} onChange={this.handleInputChange} tabIndex="31" /></Col>
																	</Row>
																</Col>
																<Col md="5">
																	<Row>
																		<Col md="2"><SetInfoCheckbox className="mt-2" name="bookInfo.setInfo.texasAMCopySent" checkedTitle="Unmark Texas A&M Copy Sent" uncheckedTitle="Mark Texas A&M Copy Sent" value={this.state.item.bookInfo.setInfo.texasAMCopySent} onChange={this.handleInputChange} tabIndex="32" /></Col>
																		<Col md="10"><Form.Control className={(this.state.item.bookInfo.setInfo.texasAMCopySentDate) ? 'has-value' : ''} type="date" name="bookInfo.setInfo.texasAMCopySentDate" value={this.formatDate(this.state.item.bookInfo.setInfo.texasAMCopySentDate)} onChange={this.handleInputChange} tabIndex="33" /></Col>
																	</Row>
																</Col>
															</Row>
															<Row className="align-items-center mx-0 py-3 border-bottom border-secondary border-top border-top-clear">
																<Col md="2">
																	<Form.Label className="mb-0">Leslie:</Form.Label>
																</Col>
																<Col md="5">
																	<Row>
																		<Col md="2"><SetInfoCheckbox className="mt-2" name="bookInfo.setInfo.leslieCopyReceived" checkedTitle="Unmark Leslie Copy Received" uncheckedTitle="Mark Leslie Copy Received" value={this.state.item.bookInfo.setInfo.leslieCopyReceived} onChange={this.handleInputChange} tabIndex="34" /></Col>
																		<Col md="10"><Form.Control className={(this.state.item.bookInfo.setInfo.leslieCopyReceivedDate) ? 'has-value' : ''} type="date" name="bookInfo.setInfo.leslieCopyReceivedDate" value={this.formatDate(this.state.item.bookInfo.setInfo.leslieCopyReceivedDate)} onChange={this.handleInputChange} tabIndex="35" /></Col>
																	</Row>
																</Col>
																<Col md="5">
																	<Row>
																		<Col md="2"><SetInfoCheckbox className="mt-2" name="bookInfo.setInfo.leslieCopySent" checkedTitle="Unmark Leslie Copy Sent" uncheckedTitle="Mark Leslie Copy Sent" value={this.state.item.bookInfo.setInfo.leslieCopySent} onChange={this.handleInputChange} tabIndex="36" /></Col>
																		<Col md="10"><Form.Control className={(this.state.item.bookInfo.setInfo.leslieCopySentDate) ? 'has-value' : ''} type="date" name="bookInfo.setInfo.leslieCopySentDate" value={this.formatDate(this.state.item.bookInfo.setInfo.leslieCopySentDate)} onChange={this.handleInputChange} tabIndex="37" /></Col>
																	</Row>
																</Col>
															</Row>
															<Row className="align-items-center mx-0 py-3 border-bottom border-secondary border-top border-top-clear">
																<Col md="2">
																	<Form.Label className="mb-0">Brag:</Form.Label>
																</Col>
																<Col md="5">
																	<Row>
																		<Col md="2"><SetInfoCheckbox className="mt-2" name="bookInfo.setInfo.bragCopyReceived" checkedTitle="Unmark Brag Copy Received" uncheckedTitle="Mark Brag Copy Received" value={this.state.item.bookInfo.setInfo.bragCopyReceived} onChange={this.handleInputChange} tabIndex="38" /></Col>
																		<Col md="10"><Form.Control className={(this.state.item.bookInfo.setInfo.bragCopyReceivedDate) ? 'has-value' : ''} type="date" name="bookInfo.setInfo.bragCopyReceivedDate" value={this.formatDate(this.state.item.bookInfo.setInfo.bragCopyReceivedDate)} onChange={this.handleInputChange} tabIndex="39" /></Col>
																	</Row>
																</Col>
																<Col md="5">
																	<Tooltip placement="bottom" title="Not Applicable">
																		<Row>
																			<Col md="2"><Form.Check className="mt-2" disabled /></Col>
																			<Col md="10"><Form.Control type="date" disabled /></Col>
																		</Row>
																	</Tooltip>
																</Col>
															</Row>
															<Row className="align-items-center mx-0 py-3 border-top border-top-clear">
																<Col md="2">
																	<Form.Label className="mb-0">Other:</Form.Label>
																</Col>
																<Col md="5">
																	<Row>
																		<Col md="2"><SetInfoCheckbox className="mt-2" name="bookInfo.setInfo.otherCopyReceived" checkedTitle="Unmark Other Copy Received" uncheckedTitle="Mark Other Copy Received" value={this.state.item.bookInfo.setInfo.otherCopyReceived} onChange={this.handleInputChange} tabIndex="39" /></Col>
																		<Col md="10">
																			<Tooltip placement="bottom" title="Not Applicable" wrapperDisplay="block"><Form.Control type="date" disabled /></Tooltip>
																		</Col>
																	</Row>
																</Col>
																<Col md="5">
																	<Tooltip placement="bottom" title="Not Applicable">
																		<Row>
																			<Col md="2"><Form.Check className="mt-2" disabled /></Col>
																			<Col md="10"><Form.Control type="date" disabled /></Col>
																		</Row>
																	</Tooltip>
																</Col>
															</Row>
														</div>
													</Collapse>
												</Col>
												<Col md="5">
	{/* Location Info */}
													<LocationInputCard className="mb-3" name="location" title={`${(this.state.item.bookInfo.setInfo.bragCopyReceived || this.state.item.bookInfo.setInfo.otherCopyReceived) ? 'Prime ' : ''}Location`} location={this.state.item.location} onChange={this.handleLocationInputChange} requiredProperties={['property', 'building', 'room']} editable disableLocking={this.props.mode === 'edit'} tabIndexStart="19" />
													<Collapse in={(this.state.item.bookInfo.setInfo.bragCopyReceived)}>
														<LocationInputCard className="mb-3" name="bragLocation" title="Brag Location" location={this.state.item.bragLocation} onChange={this.handleLocationInputChange} requiredProperties={(this.state.item.bookInfo.setInfo.bragCopyReceived) ? ['property', 'building', 'room'] : []} editable disableLocking={this.props.mode === 'edit'} tabIndexStart="40" />
													</Collapse>
													<Collapse in={(this.state.item.bookInfo.setInfo.otherCopyReceived)}>
														<LocationInputCard className="mb-3" name="otherLocation" title="Other Location" location={this.state.item.otherLocation} onChange={this.handleLocationInputChange} requiredProperties={(this.state.item.bookInfo.setInfo.otherCopyReceived) ? ['property', 'building', 'room'] : []} editable disableLocking={this.props.mode === 'edit'} tabIndexStart="41" />
													</Collapse>
												</Col>
											</Row>
										</Tab>
{/* --Appraisal Info Tab-- */}
										<Tab eventKey="appraisal-tab" title="Appraisal Info" tabIndex="-1" disabled>
											Appraisal Info Form
										</Tab>
{/* --Provenance Info Tab-- */}
										<Tab eventKey="provenance-tab" title="Provenance Info" tabIndex="-1" disabled>
											Provenance Info Form
										</Tab>
									</Tabs>
								</Col>
							</Row>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={this.handleModalClose}>Close</Button>
							<Tooltip placement="top" title="Make Changes to Save" show={this.state.isUnedited}>
								<Button variant="surprise" type="submit" disabled={this.state.isSubmitting || this.state.isUnedited} tabIndex="49">
								    <Spinner className={`${(this.state.isSubmitting) ? '' : 'd-none'} me-2`} as="span" animation="border" size="sm" role="status" aria-hidden="true" />
									{this.state.submitButtonTitle}
								</Button>
							</Tooltip>
						</Modal.Footer>
					</Form>
				</Modal>
			</>
		);
	}
}

export default AddEditItemModal;
