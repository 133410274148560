/* *** Fevre River Database *** */
/* (C) 2021 - Joti Software, LLC */

// Popover Wrapper Component //

import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

/*
	// Import as 'Popover' for simplicity
	// Example:

	import Popover from './Popover';
	
	<Popover trigger="hover|click|focus" placement="top|right|bottom|left" title="Example Popover Title!" content="Example Popover Content">
		<Button>Example Popover Target</Button>
	</Popover>
	
	// Available Props:
		- trigger: what interaction to trigger popover on (hover|click|focus)
			--Default: 'click'
		- placement: follows react-bootstrap standards for placement of the popover
			--Default: 'right'
		- title: title text for the popover
			--Default: null
		- content: HTML/JSX content, for the body of the popover
			--Default: null
		- className: any className(s) to pass on to the internal <Popover> element
			--Default: null
		- wrapperDisplay: certain uses require children to be wrapped in a container, so this sets the display property on the wrapper, to allow for consistent display depending on your use;
		 	uses standard bootstrap 'd-' display names - most common uses would be 'inline|block|inline-block'
		 	--Default: 'inline-block'
		- show: if false, displays child code without wrapping in a popover; allows easy on/off triggering of popover functionality based on another component's state
			--Default: true
*/

function PopoverWrapper(props) {
	const show = (props.show !== undefined) ? props.show : true;
	const trigger = props.trigger || 'click';
	const placement = props.placement || 'right';
	const wrapperDisplay = 'd-' + (props.wrapperDisplay || 'inline-block');
	return (
		(show)
		?	<OverlayTrigger
				trigger={trigger}
				placement={placement}
				rootClose
				overlay={
					<Popover id="popover-basic" className={props.className}>
						<Popover.Header as="h3">{props.title}</Popover.Header>
						<Popover.Body>
							{props.content}
						</Popover.Body>
					</Popover>
				}
			>
				<div className={`cursor-pointer ${wrapperDisplay}`}>{props.children}</div>
			</OverlayTrigger>
		:	props.children
	);
}
export default PopoverWrapper;