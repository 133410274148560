/* *** Fevre River Database *** */
/* (C) 2021 - Joti Software, LLC */

// Editable Select Component //

import React, { Component } from 'react';
import ReactSelect from './ReactSelect';
import axios from 'axios';

const addOptionURI = process.env.REACT_APP_API_URI + '/options/add/';

// Editable Select (allows selecting of options, and uploading of new option entries)
class EditableSelect extends Component {
	constructor(props) {
		super(props);
		
		this.handleSelectChange = this.handleSelectChange.bind(this);
		this.handleOptionAdded = this.handleOptionAdded.bind(this);

		this.state = {
			isSaving: false
		};
	}

	handleSelectChange(selectedOption, action) {
		if (action.action === 'create-option') {
			this.handleOptionAdded(selectedOption, action);
		}
		else {
			this.props.onChange(selectedOption, action);
		}
	}

	handleOptionAdded(selectedOption, action) {
		// add new option to db
		this.setState({ isSaving: true });
		const name = (action.name.includes('.')) ? action.name.split('.')[1] : action.name;
		const optionsKeyName = name + 'Options';
		const updateKeyName = this.props.updateKeyName;
		const body = { value: selectedOption.value };
		axios.post(addOptionURI+updateKeyName, body)
			.then(response => {
				// send updated options & updated value to parent
				this.props.onOptionsUpdated(response.data, optionsKeyName)
				this.props.onChange(selectedOption, action);
				this.setState({ isSaving: false });
			})
			.catch(err => this.props.onError(err, `Error saving new ${name} option .`));
	}

	render() {
		return (
			<ReactSelect
				className={this.props.className}
				name={this.props.name}
				placeholder={this.props.placeholder}
				options={this.props.options}
				value={this.props.value}
				onChange={this.handleSelectChange}
				isLoading={this.state.isSaving}
				isDisabled={this.state.isSaving || this.props.isDisabled}
				required={this.props.required} 
				tabIndex={this.props.tabIndex}
				isCreatable
			/>
		);
	}
}

export { EditableSelect };
export default EditableSelect;