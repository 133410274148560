/* *** Fevre River Database *** */
/* (C) 2022 - Joti Software, LLC */

// Item Detail View //

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Col, Form, Nav, Row, Spinner, Tab } from 'react-bootstrap';
import { DateTime } from 'luxon';
import axios from 'axios';

import AddEditItemModal from './AddEditItemModal';
import { ColorBlock } from './ColorBlock';
import { LocationCard } from './LocationCard';
import FileBlock from './FileBlock';
import Tooltip from './Tooltip';

const baseURI = process.env.REACT_APP_API_URI + '/';
const itemsURI = baseURI + 'items/';
const uploadMiscDocURI = itemsURI + 'miscDoc/';

function DetailImage(props) {
	return (
		(props.item.imageRef)
		?	<img className="detail-pic" src={baseURI+props.item.imageRef} alt="Item" />
		:	''
	);
}

function InfoItem(props) {
	return (
		(props.label && props.value)
		?	<div className="mb-1"><strong>{props.label}: </strong><span className="ml-2 text-muted">{props.value}</span></div>
		:	null
	);
}

function BusinessCollectionInfoItem(props) {
	const received = (props.setInfo[props.value+'CopyReceived']) ? 'Received' : 'Not Received';
	const receivedDate = (Date.parse(props.setInfo[props.value+'CopyReceivedDate'])) ? DateTime.fromISO(props.setInfo[props.value+'CopyReceivedDate']).toLocaleString(DateTime.DATE_MED) : null;
	const receivedString = [received, receivedDate].filter((a) => a).join(' - ');	// remove empty array elements before joining, to avoid floating separator (' - '): https://melvingeorge.me/blog/remove-empty-elements-from-array-javascript
	const sent = (props.setInfo[props.value+'CopySent']) ? 'Sent' : 'Not Sent';
	const sentDate = (Date.parse(props.setInfo[props.value+'CopySentDate'])) ? DateTime.fromISO(props.setInfo[props.value+'CopySentDate']).toLocaleString(DateTime.DATE_MED) : null;
	let sentString = [sent, sentDate].filter((a) => a).join(' - ');
	sentString = (props.value+'CopySentDate' in props.setInfo) ? sentString : null;	// don't show 'sent' messages for properties that don't track it
	const value = <span>{[receivedString, sentString].filter((a) => a).join(' • ')}</span>;
	return <div className="mb-1"><strong>{props.label}: </strong><span className="ml-2 text-muted">{value}</span></div>;
}

// TODO: move to separate component file; change to 'UserProfileInline', and add 'UserProfileCard', for separate use, and as hover popover for inline
function UserInfoItem(props) {
	const nameString = [props.user.firstName, props.user.lastName].join(' ');
	const emailString = ' (' + props.user.email + ')';
	const profilePicRef = (props.user.profilePicRef) ? baseURI + props.user.profilePicRef : '';
	const profilePic = <img className="rounded-circle me-2" src={profilePicRef} alt="Sid Khalsa" height="20px" width="20px" />;
	return (
		(props.user)
		?	<div className="d-inline-block">
				{profilePic}
				{nameString + emailString}
			</div>
		:	null
	);
}

class DetailView extends Component {

	constructor(props) {
		super(props);

		this.handleDeleteClick = this.handleDeleteClick.bind(this);
		this.handleItemUpdated = this.handleItemUpdated.bind(this);
		this.handleMiscDocInputChange = this.handleMiscDocInputChange.bind(this);
		this.handleLocationCardCollapseToggle = this.handleLocationCardCollapseToggle.bind(this);

		this.state = {
			id: props.match.params.id,
			item: {
				hasImageUpload: false,
				title: '',
				subTitle: '',
				creators: [],
				type: '',
				medium: '',
				location: {
					property: '',
					building: '',
					room: '',
					wall: '',
					case: '',
					shelf: '',
					positionX: '',
					positionY: '',
					notes: ''
				},
				bragLocation: {
					property: '',
					building: '',
					room: '',
					wall: '',
					case: '',
					shelf: '',
					positionX: '',
					positionY: '',
					notes: ''
				},
				otherLocation: {
					property: '',
					building: '',
					room: '',
					wall: '',
					case: '',
					shelf: '',
					positionX: '',
					positionY: '',
					notes: ''
				},
				quantity: '',
				countryOfOrigin: '',
				primaryColors: [],
				dimensions: {
					length: '',
					width: '',
					height: ''
				},
				appraisalInfo: null,		// TODO
				provenanceInfo: null,		// TODO
				miscDocRefs: [],
				notes: [],
				addMethod: '',
				addedBy: '',
				editHistory: [],
				linkedItems: [],
				bookInfo: {
					genre: '',
					style: '',
					subTitle: '',
					collectionSubTitles: [],
					isbn: '',
					publisher: '',
					dateOfPublication: '',
					language: '',
					bookCollection: '',
					setInfo: {
						primeCopyReceived: false,
						primeCopyReceivedDate: '',

						texasAMCopyReceived: false,
						texasAMCopyReceivedDate: '',
						texasAMCopySent: false,
						texasAMCopySentDate: '',

						leslieCopyReceived: false,
						leslieCopyReceivedDate: '',
						leslieCopySent: false,
						leslieCopySentDate: '',

						bragCopyReceived: false,
						bragCopyReceivedDate: '',

						otherCopyReceived: false
					}
				}
			},
			
			primeLocationCollapsed: false,
			bragLocationCollapsed: true,
			otherLocationCollapsed: true,

			isUploadingFile: false,
			miscDocInputValue: '',

			isDeleting: false
		};
	}

	componentDidMount() {
		axios.get(itemsURI+this.state.id)
			.then(response => {
				if (response.data) {
					this.setState({ item: response.data });
				}
				else {
					this.props.history.replace('/item-not-found');
				}
			})
			.catch(err => console.log(err));
	}

	handleItemUpdated(item) {
		this.setState({ item: item });
	}

	handleDeleteClick() {
		this.setState({ isDeleting: true });
		axios.delete(itemsURI+this.state.id)
			.then(response => {
				this.props.history.replace('/');
			})
			.catch(err => console.log(err));
	}

	handleMiscDocInputChange(e) {
		if (e.target.files && e.target.files[0]) {
			// upload file
			const itemId = this.state.item._id;
			const formData = new FormData();
			formData.append('itemId', itemId);
			formData.append('miscDoc', e.target.files[0]);
			this.setState({ isUploadingFile: true }, () => {
				axios.post(uploadMiscDocURI+itemId, formData)
					.then(response => {
						this.setState({ item: response.data, isUploadingFile: false, miscDocInputValue: '' });
					})
					.catch(err => this.handleError(err, 'Error uploading misc doc.'));
			});
		}
	}

	handleLocationCardCollapseToggle(cardTitle) {
		switch(cardTitle) {
			case 'Brag Location':
				this.setState(prevState => ({ bragLocationCollapsed: !prevState.bragLocationCollapsed }));
				break;
			case 'Other Location':
				this.setState(prevState => ({ otherLocationCollapsed: !prevState.otherLocationCollapsed }));
				break;
			default:
				this.setState(prevState => ({ primeLocationCollapsed: !prevState.primeLocationCollapsed }));
		}
	}

	handleError(error, callingAction) {
		if (axios.isCancel(error)) {
			console.log(error);
		}
		else {
			const alertMessage = 'Oops! It looks like something went wrong. Please try again in a little while. Support info: [' + callingAction + ']';
			console.group('Error encountered:');
			console.log(callingAction);
			console.log(error);
			console.log('Current component state:');
			console.log(this.state);
			console.groupEnd();
			alert(alertMessage);
		}
	}

	imageKey() {
		// set image key to date of last edit that changed image, to force re-render on any image change (since image changes don't change imageRef in state)
		const indexOfLastImageEdit = this.state.item.editHistory.map(edit => (edit.editedProperties && edit.editedProperties.imageRef === '<new-image>')).lastIndexOf(true);
		const lastImageEditDate = this.state.item.editHistory[indexOfLastImageEdit] && this.state.item.editHistory[indexOfLastImageEdit].editedAt;
		return lastImageEditDate;
	}

	subTitles() {
		const subTitles = [this.state.item.subTitle].concat([this.state.item.bookInfo.subTitle].concat(this.state.item.bookInfo.collectionSubTitles));
		return subTitles.filter((a) => a).join(' • ');	// see same filter code above
	}

	colorBlocks() {
		const primaryColors = this.state.item.primaryColors;
		return (
			(primaryColors.length)
			?	primaryColors.map((currentItem, i) => {
					return <ColorBlock variant="display" key={currentItem.value} option={currentItem} />;
				})
			: ''
		);
	}

	dimensionsString() {
		const dimensions = this.state.item.dimensions;
		const values = [dimensions.length, dimensions.width, dimensions.height];
		return (
			(dimensions.length || dimensions.width || dimensions.height)
			?	values.join(' x ') + ' inches'
			:	null
		);
	}

	publicationString() {
		const publisher = this.state.item.bookInfo.publisher;
		const dateOfPublication = this.state.item.bookInfo.dateOfPublication;
		const dateString = (dateOfPublication && dateOfPublication.length) ? '; (' + dateOfPublication + ')' : ''
		return publisher + dateString;
	}

	miscDocsFileBlocks() {
		return (
			(this.state.item.miscDocRefs.length)
			?
				<div className="mb-3">
					{
						this.state.item.miscDocRefs.map((currentItem, i) => {
							const fileName = currentItem.split(' *|* ')[1];
							const fileRef = baseURI + currentItem;
							return <FileBlock key={currentItem} fileName={fileName} fileRef={fileRef} />;
						})
					}
				</div>
			:	''
		);
	}

	render() {
		return (
			<div className="fw-light mx-3">
				<Row>
					<Col>
						<h2 className="fw-light mb-1">
							{this.state.item.title}
							<span className="fs-4 fw-light ms-3"><em>{this.subTitles()}</em></span>
						</h2>
					</Col>
					<Col sm="auto">
						<AddEditItemModal buttonClassName="float-end" key={this.state.item._id+this.props.currentUser._id} mode="edit" item={this.state.item} currentUser={this.props.currentUser} onItemUpdated={this.handleItemUpdated} />		{/* key set to ids to force re-render when item/currentUser is updated (https://stackoverflow.com/a/48434525/2155673) */}
					</Col>
				</Row>
				<Row className="mb-2">
					<Col>
						<h5 className="fw-light text-muted"><em>{this.state.item.creators.join(' • ')}</em></h5>
					</Col>
					<Col>
						<h5 className="fw-light float-end"><em>{this.state.item.type + ' - ' + this.state.item.medium}</em></h5>
					</Col>
				</Row>
				<Row>
					<Col className="DetailImage__Col">
						<Row className="mb-4">
							<Col>
								<DetailImage key={this.imageKey()} item={this.state.item} />
							</Col>
						</Row>
						<Row>
							<Col>
								<h5 className="fw-light text-muted mb-3"><strong>Additional Documents:</strong></h5>
								{this.miscDocsFileBlocks()}
								<Form.Group className="position-relative mb-3">
									<Form.Control type="file" onChange={this.handleMiscDocInputChange} value={this.state.miscDocInputValue} disabled={this.state.isUploadingFile} />
								    <Spinner className={`${(this.state.isUploadingFile) ? '' : 'd-none'} me-2`} as="span" animation="border" size="sm" role="status" aria-hidden="true" />
								    <span className={`loading-label ${(this.state.isUploadingFile) ? '' : 'd-none'}`}>Loading...</span>
								</Form.Group>
							</Col>
						</Row>
					</Col>
					<Col>
						<Tab.Container id="detail-view-tabs" defaultActiveKey="general-tab" className="mb-3">
							<Nav variant="tabs">
								<Nav.Item>
									<Nav.Link eventKey="general-tab" tabIndex="-1">General Info</Nav.Link>
								</Nav.Item>
								<Tooltip placement="top" title="To Be Developed..." show="false">
									<Nav.Item>
										<Nav.Link eventKey="appraisal-tab" tabIndex="-1">Appraisal Info</Nav.Link>
									</Nav.Item>
								</Tooltip>
								<Tooltip placement="top" title="To Be Developed..." show="false">
									<Nav.Item>
										<Nav.Link eventKey="provenance-tab" tabIndex="-1">Provenance Info</Nav.Link>
									</Nav.Item>
								</Tooltip>
								<Tooltip placement="top" title="In Development..." show="false">
									<Nav.Item>
										<Nav.Link eventKey="history-tab" tabIndex="-1" className="text-muted">Item History</Nav.Link>
									</Nav.Item>
								</Tooltip>
							</Nav>
							<Tab.Content>
{/* --General Info Tab-- */}
								<Tab.Pane eventKey="general-tab">
									<Row className="mt-4">
										<Col md="8">
											{
												(this.state.item.type === 'Book')
												?
													<div className="mb-4">
														<h5 className="fw-light text-muted"><strong>Book Info:</strong></h5>
														<InfoItem label="Publisher" value={this.publicationString()} />
														<InfoItem label="Language" value={this.state.item.bookInfo.language} />
														<InfoItem label="Genre" value={this.state.item.bookInfo.genre} />
														<InfoItem label="Style" value={this.state.item.bookInfo.style} />
														<InfoItem label="ISBN" value={this.state.item.bookInfo.isbn} />
														<InfoItem label="Collection" value={this.state.item.bookInfo.bookCollection} />
														<hr />
													</div>
												: ''
											}
											<div>
												<h5 className="fw-light text-muted"><strong>Physical Info:</strong></h5>
												<InfoItem label="Quantity" value={this.state.item.quantity} />
												<InfoItem label="Country of Origin" value={this.state.item.countryOfOrigin} />
												<InfoItem label="Dimensions" value={this.dimensionsString()} />
												<InfoItem label="Primary Color(s)" value={this.colorBlocks()} />
											</div>
											{
												(this.state.item.type === 'Book' && this.state.item.bookInfo.bookCollection === 'Business')
												?
													<div className="mb-5">
														<hr className="mb-4"/>
														<h5 className="fw-light text-muted"><strong>Business Collection Info:</strong></h5>
														<BusinessCollectionInfoItem label="Prime Copy" value="prime" setInfo={this.state.item.bookInfo.setInfo} />
														<BusinessCollectionInfoItem label="Texas A&M Copy" value="texasAM" setInfo={this.state.item.bookInfo.setInfo} />
														<BusinessCollectionInfoItem label="Leslie Copy" value="leslie" setInfo={this.state.item.bookInfo.setInfo} />
														<BusinessCollectionInfoItem label="Brag Copy" value="brag" setInfo={this.state.item.bookInfo.setInfo} />
														<BusinessCollectionInfoItem label="Other Copy" value="other" setInfo={this.state.item.bookInfo.setInfo} />
													</div>
												: ''
											}
										</Col>
										<Col md="4">
											<LocationCard location={this.state.item.location} title={(this.state.item.bookInfo.setInfo.bragCopyReceived || this.state.item.bookInfo.setInfo.otherCopyReceived) ? 'Prime Location' : 'Location'} handleCollapseToggle={this.handleLocationCardCollapseToggle} collapseIn={!this.state.primeLocationCollapsed} className="mb-3" />
											<LocationCard location={this.state.item.bragLocation} title="Brag Location" handleCollapseToggle={this.handleLocationCardCollapseToggle} collapseIn={!this.state.bragLocationCollapsed} className="mb-3" />
											<LocationCard location={this.state.item.otherLocation} title="Other Location" handleCollapseToggle={this.handleLocationCardCollapseToggle} collapseIn={!this.state.otherLocationCollapsed} className="mb-3" />
											
											<Button className="mt-5 mb-4 float-end" variant="danger" size="xs" onClick={this.handleDeleteClick} disabled={this.state.isDeleting}>
												<Spinner className={`${(this.state.isDeleting) ? '' : 'd-none'} me-2`} as="span" animation="border" size="sm" role="status" aria-hidden="true" />
												Delete Item
											</Button>
										</Col>
									</Row>
								</Tab.Pane>
{/* --Appraisal Info Tab-- */}
								<Tab.Pane eventKey="appraisal-tab">
									<div className="text-light m4-3"><em>To Be Developed...</em></div>
								</Tab.Pane>
{/* --Provenance Info Tab-- */}
								<Tab.Pane eventKey="provenance-tab">
									<div className="text-light m4-3"><em>To Be Developed...</em></div>
								</Tab.Pane>
{/* --Item History Tab-- */}
								<Tab.Pane eventKey="history-tab">
									<div className="mt-4 mb-4">
										<h5 className="fw-light text-muted"><strong>Item Add Info:</strong></h5>
										<InfoItem label="Added By" value={<UserInfoItem user={this.state.item.addedBy} />} />
										<InfoItem label="Added On" value={DateTime.fromISO(this.state.item.createdAt).toLocaleString(DateTime.DATETIME_FULL)} />
										<InfoItem label="Add Method" value={this.state.item.addMethod} />
										<hr />
									</div>
									<div className="mb-4">
										<h5 className="fw-light text-muted"><strong>Edit History:</strong></h5>
										<div className="text-light mb-3"><em>In Development...</em></div>
										<div className="text-break mb-3">
											{JSON.stringify(this.state.item.editHistory)}
										</div>
									</div>
								</Tab.Pane>
							</Tab.Content>
						</Tab.Container>
					</Col>
				</Row>
			</div>
		);
	}
}

export default withRouter(DetailView);