/* *** Fevre River Database *** */
/* (C) 2022 - Joti Software, LLC */

// Logout Button Component //

import React from 'react';
import { Button } from 'react-bootstrap';
import { useAuth0 }  from '@auth0/auth0-react';

const LogoutButton = (props) => {
	const { logout } = useAuth0();
    return (
        <Button {...props} onClick={() => logout({ returnTo: window.location.origin })}>
        	Log Out
        </Button>
    );
};

export default LogoutButton;
